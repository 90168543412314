/* eslint-disable @typescript-eslint/no-explicit-any */
import type { TRPCClientErrorLike } from '@trpc/react-query'
import { getQueryKey } from '@trpc/react-query'
import type {
  TRPCFetchQueryOptions,
  UseTRPCInfiniteQueryOptions,
  UseTRPCInfiniteQueryResult,
  UseTRPCMutationOptions,
  UseTRPCMutationResult,
  UseTRPCQueryOptions,
  UseTRPCQueryResult,
} from '@trpc/react-query/shared'
import type { AnyRouter, inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import type { Document } from 'mongodb'
import React from 'react'
import { useCorpCryptId } from '~/client/lib/hooks/corp'
import type { ZPagination, ZRelationPaginationSortField } from '~/common/pagination'
import type { Paginated, ZRelationFilter } from '~/common/schema'
import type { AugmentRelation, ZRelationTypeValues } from '~/common/schema/relation'
import type { OmitUnion } from '~/common/util-types'
import { hooks } from './dependency-injection/interface'

/** Just to avoid catastrophic typos. */
export const useQueryWithCorpKey = 'useQueryWithCorp'
export const useInfiniteQueryWithCorpKey = 'useInfiniteQueryWithCorp'
export const useMutationWithCorpKey = 'useMutationWithCorp'
export const useFetchKey = 'useFetch'
export const useFetchWithCorpKey = 'useFetchWithCorp'

type VoidIfEmptyObj<T> = {} extends T ? void : T
// TS considers void parameters as optional. We use it as it's simpler to define and understand than conditional optional parameters.
// The downside of it is that it doesn't show (input?: void), but (input: void), but it's still optional.
type OmitCorpCryptIdAndVoidIfEmptyObj<T> = VoidIfEmptyObj<OmitUnion<T, 'corpCryptId'>>

interface UseQueryWithCorp<Input extends Record<string, any>, Output> {
  useQueryWithCorp: (
    input: OmitCorpCryptIdAndVoidIfEmptyObj<Input>,
    opts?: UseTRPCQueryOptions<any, Input, any, Output, unknown>
  ) => UseTRPCQueryResult<Output, unknown>
}

interface UseInfiniteQueryWithCorp<Input extends Record<string, any>, Output> {
  useInfiniteQueryWithCorp: (
    input: OmitCorpCryptIdAndVoidIfEmptyObj<Input>,
    opts?: UseTRPCInfiniteQueryOptions<any, Input, Output, unknown>
  ) => UseTRPCInfiniteQueryResult<Output, unknown>
}

interface UseMutationWithCorp<TInput, TError, TOutput> {
  useMutationWithCorp: <TContext = unknown>(
    opts?: UseTRPCMutationOptions<TInput, TError, TOutput, TContext>
  ) => UseTRPCMutationResult<TOutput, TError, OmitCorpCryptIdAndVoidIfEmptyObj<TInput>, TContext>
}

interface UseFetch<TInput, TError, TOutput> {
  useFetch: (
    opts?: TRPCFetchQueryOptions<TInput, TError, TOutput>
  ) => (input: VoidIfEmptyObj<TInput>) => Promise<TOutput>
}

interface UseFetchWithCorp<TInput, TError, TOutput> {
  useFetchWithCorp: (
    opts?: TRPCFetchQueryOptions<TInput, TError, TOutput>
  ) => (input: OmitCorpCryptIdAndVoidIfEmptyObj<TInput>) => Promise<TOutput>
}

interface UseFetchAndQueryWithCorp<
  Input extends Record<string, any>,
  Output,
  TAppRouter extends AnyRouter,
> extends UseQueryWithCorp<Input, Output>,
    UseFetchWithCorp<Input, TRPCClientErrorLike<TAppRouter>, Output> {}

type AddNewProps<
  Trpc extends Record<string, any>,
  Input extends Record<string, any>,
  Output extends Record<string, any>,
  TAppRouter extends AnyRouter,
> = {
  [K in keyof (Trpc | Input | Output)]: Trpc[K] extends
    | { useQuery: any }
    | { useMutation: any }
    | { useInfiniteQuery: any }
    ? // eslint-disable-next-line custom-rules/prefer-extends-to-type-intersection
      Trpc[K] &
        (Trpc[K] extends { useQuery: any } | { useInfiniteQuery: any }
          ? UseFetch<Input[K], TRPCClientErrorLike<TAppRouter>, Output[K]>
          : {}) &
        // If input has corp, we can add the corresponding WithCorp hook/s
        (Input[K] extends { corpCryptId: any }
          ? // eslint-disable-next-line custom-rules/prefer-extends-to-type-intersection
            (Trpc[K] extends { useQuery: any }
              ? UseFetchAndQueryWithCorp<Input[K], Output[K], TAppRouter>
              : {}) &
              // useInfiniteQuery exists simultaneously with useQuery
              (Trpc[K] extends { useInfiniteQuery: any }
                ? UseInfiniteQueryWithCorp<Input[K], Output[K]>
                : {}) &
              (Trpc[K] extends { useMutation: any }
                ? UseMutationWithCorp<Input[K], TRPCClientErrorLike<TAppRouter>, Output[K]>
                : {})
          : {})
    : AddNewProps<Trpc[K], Input[K], Output[K], TAppRouter>
}

/** The TRPC properties that aren't routers & procedures */
type PickTrpcUtils<Trpc extends Record<string, any>> = Pick<
  Trpc,
  'Provider' | 'createClient' | 'useQueries' | 'useDehydratedState' | 'withTRPC' | 'useContext'
>
/** The TRPC without the util properties above */
type OmitTrpcUtils<Trpc extends Record<string, any>> = Omit<Trpc, keyof PickTrpcUtils<Trpc>>

// eslint-disable-next-line custom-rules/prefer-extends-to-type-intersection
export type GetMyTrpc<Trpc extends Record<string, any>, TAppRouter extends AnyRouter> = AddNewProps<
  OmitTrpcUtils<Trpc>,
  inferRouterInputs<TAppRouter>,
  inferRouterOutputs<TAppRouter>,
  TAppRouter
> &
  PickTrpcUtils<Trpc>

export const useQueryWithCorp = (useQuery: (...p: any) => any, input: any, opts: any): any => {
  const { corpCryptId } = useCorpCryptId()
  // Put the corpCryptId at last so that it will override input.corpCryptId for the sake of safety
  return useQuery({ ...input, corpCryptId }, opts)
}

export const useInfiniteQueryWithCorp = (
  useInfiniteQuery: (...p: any) => any,
  input: any,
  opts: any
): any => {
  const { corpCryptId } = useCorpCryptId()
  // Put the corpCryptId at last so that it will override input.corpCryptId for the sake of safety
  return useInfiniteQuery({ ...input, corpCryptId }, opts)
}

export const useMutationWithCorp = (
  useMutation: (opts: any) => UseTRPCMutationResult<any, any, any, any>,
  opts: any
): any => {
  const { corpCryptId } = useCorpCryptId()
  const mutation = useMutation(opts)

  const mutationMutateAsync = mutation.mutateAsync // ESLint doesn't like it on depArray

  const mutateAsync: any = React.useCallback(
    async (p: any) => {
      // Put the corpCryptId at last so that it will override input.corpCryptId for the sake of safety
      return mutationMutateAsync({ ...p, corpCryptId })
    },
    [mutationMutateAsync, corpCryptId]
  )

  const mutate: any = React.useCallback(
    (p: any) => {
      mutateAsync(p)
    },
    [mutateAsync]
  )

  return {
    ...mutation,
    mutateAsync,
    mutate,
  }
}

const useFetch = (trpc: any, target: any, opts: any) => {
  const context = trpc.useContext()
  const path = getQueryKey(target)[0]

  // Can't memoize the whole expression because target is a Proxy (difficult to compare)
  const memoPath = React.useMemo(
    () => path,
    // https://github.com/facebook/react/issues/18243#issuecomment-596057310
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...path]
  )

  return React.useCallback(
    (input: any) => {
      // This needs to be in a function to run after the context is defined
      // Access nested objects by the path array
      const contextTarget = memoPath.reduce((obj, field) => obj?.[field], context)
      return contextTarget.fetch(input, opts)
    },
    [context, opts, memoPath]
  )
}

const useFetchWithCorp = (trpc: any, target: any, opts: any) => {
  const { corpCryptId } = useCorpCryptId()
  const baseFetch = useFetch(trpc, target, opts)
  return React.useCallback(
    (input: any) => baseFetch({ ...input, corpCryptId }),
    [corpCryptId, baseFetch]
  )
}

/** Returns a new trpc with our custom hooks. */
export const getMyTrpc = <Trpc extends Record<string, any>, TAppRouter extends AnyRouter>(
  trpc: Trpc
): GetMyTrpc<Trpc, TAppRouter> => {
  // TRPC in the client works with proxies. The client only knows the server type, but it can't know that a.b.c.useQuery exists before trying to call it.
  // What we do here is a wrapping proxy around their proxy. If the property we are accessing is the ones we added,
  // we handle it, else, let it execute as trpc knows.
  // Proxy for nested objects: https://stackoverflow.com/a/41300128
  const handler: ProxyHandler<Record<string, any>> = {
    get: (target, key) => {
      if (key === useQueryWithCorpKey)
        return (input: any, opts: any) => useQueryWithCorp(target.useQuery, input, opts)
      if (key === useInfiniteQueryWithCorpKey)
        return (input: any, opts: any) =>
          useInfiniteQueryWithCorp(target.useInfiniteQuery, input, opts)

      if (key === useMutationWithCorpKey)
        return (opts: any) => useMutationWithCorp(target.useMutation, opts)

      if (key === useFetchKey) return (opts: any) => useFetch(trpc, target, opts)
      if (key === useFetchWithCorpKey) return (opts: any) => useFetchWithCorp(trpc, target, opts)
      return new Proxy(target[key as keyof typeof target], handler)
    },
  }
  return new Proxy(trpc, handler) as any
}

export interface UseRelationsByTypeParam<T extends ZRelationTypeValues> extends ZPagination {
  types: T[]
  filter: ZRelationFilter | undefined
  sortField: ZRelationPaginationSortField
}

/**
 * tRPC doesn't allow generic queries. This is a workaround to have
 * return types that take in account the input types.
 */
export const useRelationsByType = <T extends ZRelationTypeValues>(
  { types, sortField, filter, ...pagination }: UseRelationsByTypeParam<T>,
  opts?: UseTRPCQueryOptions<any, any, any, any, any>
): UseTRPCQueryResult<Paginated<AugmentRelation<{ type: T }>>, unknown> => {
  return hooks.trpc().relations.byType.useQueryWithCorp(
    { types, sortField, filter, ...pagination },
    {
      ...(opts as any), // Error without as any as its generics aren't fully typed.
      enabled: opts?.enabled ?? true,
    }
  ) as UseTRPCQueryResult<Paginated<AugmentRelation<{ type: T }>>, unknown>
}

export const nextPageParamOpts = <T extends Document>(): Pick<
  UseTRPCInfiniteQueryOptions<unknown, unknown, Pick<Paginated<T>, 'nextCursor'>, unknown>,
  'getNextPageParam'
> => ({
  getNextPageParam: (lastPage: Pick<Paginated<T>, 'nextCursor'>) => lastPage.nextCursor,
})

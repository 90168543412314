import type { UseFormReturnType } from '@mantine/form'
// eslint-disable-next-line custom-rules/no-use-debounced-value
import { useDebouncedValue } from '@mantine/hooks'
import { mapObject } from 'underscore'
import type { ZDocForm } from '~/client/components/doc-detail/state'
import type { DocAutofill } from '~/common/enhance'

export type AutocheckValue = 'ok' | 'warning'

export interface AutocheckResult {
  autocheckAllOk: boolean
  // Using literals to avoid potential mistakes with booleans
  autocheckFieldResults: Record<keyof DocAutofill, AutocheckValue>
}

interface AutocheckProps {
  form: UseFormReturnType<ZDocForm>
  autofillWithHiddenSuggestions?: DocAutofill
  enabled: boolean
}

export const useAutocheck = ({
  enabled,
  form,
  autofillWithHiddenSuggestions,
}: AutocheckProps): AutocheckResult => {
  // Wait for the user to finish typing
  const [values] = useDebouncedValue(form.values, 200, {
    leading: false, // Immediately update on first change
  })

  if (!enabled || !autofillWithHiddenSuggestions)
    return {
      autocheckAllOk: true,
      autocheckFieldResults: {
        titles: 'ok',
        parties: 'ok',
        emails: 'ok',
        startDates: 'ok',
        types: 'ok',
      },
    }

  const checkStrArray = (arr: string[], search: string) =>
    arr.map((v) => v.trim().toLowerCase()).includes(search.trim().toLowerCase())

  const autoCheckOk = {
    titles:
      !values.title ||
      !autofillWithHiddenSuggestions.titles.length ||
      checkStrArray(autofillWithHiddenSuggestions.titles, values.title),
    emails:
      !values.party.email ||
      !autofillWithHiddenSuggestions.emails.length ||
      checkStrArray(autofillWithHiddenSuggestions.emails, values.party.email),
    parties:
      !values.party.name ||
      !autofillWithHiddenSuggestions.parties.length ||
      checkStrArray(
        autofillWithHiddenSuggestions.parties.map((v) => v.name),
        values.party.name
      ),
    types:
      !autofillWithHiddenSuggestions.types.length ||
      values.type === 'PROCESSING' ||
      autofillWithHiddenSuggestions.types.includes(values.type),
    startDates:
      !values.startDate ||
      !autofillWithHiddenSuggestions.startDates.length ||
      autofillWithHiddenSuggestions.startDates
        .map((v) => v.getTime())
        .includes(values.startDate.getTime()),
  }

  return {
    autocheckAllOk: Object.values(autoCheckOk).every(Boolean),
    autocheckFieldResults: mapObject(autoCheckOk, (result) => (result ? 'ok' : 'warning')),
  }
}

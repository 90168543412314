/** Extract the DocuSign envelope id from the PDF if it exists.
 * Useful to dedupe documents from DocuSign, which have different signatures on
 * every download */
export const extractDocusignIdFromPdf = (
  pdf: ArrayBuffer | ArrayBufferView
): string | undefined => {
  const pdfString = new TextDecoder().decode(pdf)
  // Match the last instance of the regex on the pdf string, extracting only
  // the envelope with group matching.
  // The envelope id is usually found at the end of the document, after all the
  // text / images.
  // This should never match text on a PDF, as it's very unlikely a legal document has a
  // string that matches this regex.
  const envelopeIdString = pdfString.match(/\[\(ENVELOPEID_([0-9A-F]{32})\)\]/)?.at(-1)
  if (!envelopeIdString || envelopeIdString.length !== 32) return undefined
  // format as docusign expects it (with every [x] denoting that there are x characters in that string)
  // [8]-[4]-[4]-[4]-[12]
  const envelopeIdSectionStrings = [
    envelopeIdString.slice(0, 8),
    envelopeIdString.slice(8, 12),
    envelopeIdString.slice(12, 16),
    envelopeIdString.slice(16, 20),
    envelopeIdString.slice(20),
  ]
  return envelopeIdSectionStrings.join('-').toLowerCase()
}

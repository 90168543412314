import type { CryptId } from '@cryptid-module'
import type { UseQueryResult } from '@tanstack/react-query'
import type { inferProcedureInput } from '@trpc/server'
import React from 'react'
import type { UsePagination } from '~/client/lib/hooks'
import { hooks } from '~/client/lib/hooks'
import { nextPageParamOpts } from '~/client/lib/hooks/query'
import { useDocSearchState } from '~/client/lib/hooks/search'
import { splitTypeAndTextQueries } from '~/client/lib/hooks/search/util'
import type { ZNumberExceed } from '~/common/number-exceed'
import type { Paginated } from '~/common/schema'
import { ZAugmentedRelation } from '~/common/schema/relation'
import type { AppRouter } from '~/common/trpc/app-router'

interface UseRelationsSearchProps extends Pick<UsePagination, 'getQueryParams'> {
  enabled?: boolean
  queries?: string[]
  autofillCryptIds?: CryptId[]
  getAllRelationsWhenNoTypes?: boolean
}

export interface RelationsSearchParams
  extends Omit<inferProcedureInput<AppRouter['relations']['search']>, 'corpCryptId'> {}

interface UseRelationsSearch {
  setQuery: (value: string[]) => void
  searchResults: UseQueryResult<Paginated<ZAugmentedRelation>>
  noQuery: boolean
  searchParams: RelationsSearchParams
}

export const useRelationSearch = ({
  enabled,
  queries = [],
  autofillCryptIds = [],
  getAllRelationsWhenNoTypes,
  getQueryParams,
}: UseRelationsSearchProps): UseRelationsSearch => {
  const [debouncedValue, setDebouncedValue] = useDocSearchState(queries)

  const { texts, types } = splitTypeAndTextQueries(debouncedValue)
  const relationTypes = types.filter(ZAugmentedRelation.isType)

  const searchParams = {
    ...getQueryParams(),
    types: relationTypes,
    query: texts,
    autofillCryptIds,
    getAllRelationsWhenNoTypes,
  } as const

  const searchResults = hooks.trpc().relations.search.useQueryWithCorp(searchParams, {
    keepPreviousData: true,
    ...nextPageParamOpts(),
    enabled,
  })

  const trpcContext = hooks.trpc().useContext()
  const setQuery = React.useCallback(
    async (v: string[]) => {
      await trpcContext.relations.search.cancel()
      setDebouncedValue(v)
    },
    [setDebouncedValue, trpcContext.relations.search]
  )

  const noQuery = relationTypes.length === 0 && texts.length === 0

  return {
    setQuery,
    searchResults,
    noQuery,
    searchParams,
  }
}

export const mkRelationsAndCount = (
  searchResults: UseRelationsSearch['searchResults']
): { relations: ZAugmentedRelation[]; count: ZNumberExceed } => {
  const relations = searchResults.data?.data ?? []
  const count = searchResults.data?.count ?? { count: 0, exceeds: false }
  return { relations, count }
}

import { omit } from 'underscore'
import { z } from 'zod'
import { ZLawFirm } from '~/common/law-firms'
import type { ZAugmentedDoc } from '~/common/schema/doc'
import { AugmentedDocDate, AugmentedDocString } from '~/common/schema/doc.util'
import {
  AugmentedMetadataDate,
  AugmentedMetadataNumber,
  AugmentedMetadataPercentage,
  AugmentedMetadataPrice2,
  AugmentedMetadataPrice4,
  AugmentedMetadataString,
  AugmentedMetadataUsePool,
  ZWarningAggregate,
} from '~/common/schema/metadata'
import { ZParty } from './party'
import { ZStateEnum } from './relation/state-enum'

export const MetadataSchemaMap = {
  party: ZParty,
  aggregate: ZWarningAggregate,
  price2: AugmentedMetadataPrice2,
  price4: AugmentedMetadataPrice4,
  date: AugmentedMetadataDate,
  string: AugmentedMetadataString,
  number: AugmentedMetadataNumber,
  pool: AugmentedMetadataUsePool,
  state: ZStateEnum,
  lawFirm: ZLawFirm,
  docString: AugmentedDocString,
  docDate: AugmentedDocDate,
  percentage: AugmentedMetadataPercentage,
}

export type MetadataSchemaMap = typeof MetadataSchemaMap

export type MetadataSchemaKeys = keyof MetadataSchemaMap

interface BaseZod<T extends MetadataSchemaKeys>
  extends z.ZodType<
    MetadataSchemaMap[T]['_output'],
    MetadataSchemaMap[T]['_def'],
    MetadataSchemaMap[T]['_output']
  > {
  // Having this property asserts to TS that the schema should have a display prop
  display: MetadataSchemaMap[T]['display']
}

export type ZodBySchema<T extends MetadataSchemaKeys> = BaseZod<T> | z.ZodOptional<BaseZod<T>>

type DisplayPropsFn<
  SchemaKey extends MetadataSchemaKeys,
  ZodType extends ZodBySchema<SchemaKey>,
> = <PropertyName extends string>(
  // eslint-disable-next-line custom-rules/prefer-extends-to-type-intersection
  data: Partial<Record<PropertyName, z.infer<ZodType>>> & { docs?: ZAugmentedDoc[] },
  update: (value: Record<PropertyName, z.infer<ZodType>>) => Promise<unknown>,
  property: PropertyName
) => MetadataDisplayProps<SchemaKey>

export const ZSimplifiedDisplaySchema = z.object({
  display: z.string(),
  displayFn: z.function(),
})
export interface ZSimplifiedDisplaySchema extends z.infer<typeof ZSimplifiedDisplaySchema> {}

export type WithDisplaySchema<
  SchemaKey extends MetadataSchemaKeys,
  ZodType extends ZodBySchema<SchemaKey>,
> = ZodType & {
  mkDisplayProps: DisplayPropsFn<SchemaKey, ZodType>
  displaySchema: {
    display: string
    schema: SchemaKey
    displayFn: MetadataSchemaMap[SchemaKey]['display']
  }
}

export interface MetadataDisplayProps<SchemaKey extends MetadataSchemaKeys = MetadataSchemaKeys> {
  display: string
  schema: SchemaKey
  initialValue?: z.infer<MetadataSchemaMap[SchemaKey]>
  update?: (value: z.infer<MetadataSchemaMap[SchemaKey]>) => Promise<unknown>
  docs?: ZAugmentedDoc[]
}

const findDisplay = <T extends ZodBySchema<MetadataSchemaKeys>>(zod: T) => {
  if ('display' in zod) return zod.display
  // No need to check instanceof, because TS already infers based on `display`
  if ('display' in zod._def.innerType) return zod._def.innerType.display
  throw new Error('Display not found on schema')
}

export const withDisplaySchema = <
  SchemaKey extends MetadataSchemaKeys,
  ZodType extends ZodBySchema<SchemaKey>,
>(
  zod: ZodType,
  schema: SchemaKey,
  display: string
): WithDisplaySchema<SchemaKey, ZodType> => {
  const mkDisplayProps: DisplayPropsFn<SchemaKey, ZodType> = (data, update, property) => ({
    display,
    schema,
    initialValue: data[property],
    // TS infers the type of [property] as string instead of the generic, so casting is necessary
    update: (value) => update({ [property]: value } as Record<typeof property, typeof value>),
    docs: data.docs,
  })

  // We use `describe` intentionally to create a new instance of the same schema
  const cloned = zod.describe(display)
  // describe won't keep some custom attributes like `__type: "Metadata"`
  const clonedWithAdditionalData = Object.assign(
    cloned as typeof zod,
    omit(zod, ...Object.keys(cloned)) // Do not overwrite keys
  )

  // We use `describe` intentionally to create a new instance of the same schema
  return Object.assign(clonedWithAdditionalData, {
    displaySchema: { display, schema, displayFn: findDisplay(zod) },
    mkDisplayProps,
  })
}

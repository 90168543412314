import type { UseTRPCQueryResult } from '@trpc/react-query/shared'
import { useRouter } from 'next/router'
import React from 'react'
import { create } from 'zustand'
import { hooks, useCorpCryptId, useJwtStore } from '~/client/lib/hooks'
import { GenericErrorHandler } from '~/common/error-handler'
import type {
  ZDocusignAccessToken,
  ZDocusignAccountInfo,
} from '~/common/schema/integration/docusign'

const useHandleDocusignAuthError = () => {
  const router = useRouter()
  const { mkCurrentCorpRoute } = useCorpCryptId()
  return ({
    message,
    cause,
    notification,
  }: {
    message: string
    cause?: unknown
    notification: boolean
  }) => {
    const { message: errorMessage, sentryEventId } = GenericErrorHandler.createAndCapture(message, {
      cause,
    })
    const redirectRoute = mkCurrentCorpRoute('integrations')
    if (notification) {
      const errorQueryString = new URLSearchParams({
        sentryEventId,
        errorMessage,
      }).toString()

      return router.push(`${redirectRoute}?${errorQueryString}`)
    }
    return router.push(redirectRoute)
  }
}

interface DocusignAuthCodeStore {
  /** Authorization Code returned by DocuSign as a query parameter. We use this
   * as a query key for the accessToken since we only want to generate a single
   * token per code.  */
  code?: string
  setCode: (code: string) => void
}

export const useDocusignAuthCodeStore = create<DocusignAuthCodeStore>()((set) => ({
  setCode: (code) => set({ code }),
}))

/** Returns the Authorization Code from the store, and tries to update it with the code in
 * the URL. If there is no code in either, this handles the error.*/
const useDocusignCode = (): string | undefined => {
  const router = useRouter()
  const { code, ...otherQueryParams } = router.query
  const handleDocusignAuthError = useHandleDocusignAuthError()

  const storedCode = useDocusignAuthCodeStore((state) => state.code)
  const setStoredCode = useDocusignAuthCodeStore((state) => state.setCode)

  React.useEffect(() => {
    if (!code && !storedCode) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleDocusignAuthError({
        message: 'No code in URL and code not stored in the state',
        notification: false,
      })
    }
    if (typeof code === 'string') {
      setStoredCode(code)
      // remove the code from the URL so users can share it
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.replace(
        {
          query: otherQueryParams,
        },
        undefined,
        { shallow: true, scroll: false }
      )
    }
  }, [code, router, otherQueryParams, handleDocusignAuthError, storedCode, setStoredCode])
  return storedCode
}
/** Calls useDocusignCode to get the code from the URL or the store */
const useDocusignAccessToken = (): UseTRPCQueryResult<ZDocusignAccessToken, unknown> => {
  const code = useDocusignCode()
  return hooks.trpc().integration.docusign.exchangeCodeForToken.useQueryWithCorp(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { code: code! },
    {
      enabled: !!code,
      // we only want to refetch the token when the code changes.
      meta: { noGlobalInvalidation: true },
    }
  )
}

/** Calls useDocusignCode and useDocusignAccessToken to get the code from the
 * URL or the store, and fetch the accessToken. Uses react-query to avoid
 * executing multiple times. */
export const useDocusignAccounts = (): { data: ZDocusignAccountInfo[]; isLoading: boolean } => {
  const accessTokenResult = useDocusignAccessToken()
  const { accessToken } = accessTokenResult.data ?? {}

  const docusignUserInfoResult = hooks.trpc().integration.docusign.getUserInfo.useQueryWithCorp(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { accessToken: accessToken! },
    // We only want to re-run this query when the access token changes, not when
    // the user triggers mutations on the app.
    {
      enabled: !!accessToken,
      meta: { noGlobalInvalidation: true },
      // Store our JWT that validates that the user has access to these accounts
      onSuccess: ({ aerialJwt }) => {
        useJwtStore.setState({ aerialDocusignJwt: aerialJwt })
      },
    }
  )
  const accountsWithUserId = !docusignUserInfoResult.data?.userInfo.accounts
    ? []
    : docusignUserInfoResult.data.userInfo.accounts.map((account) => ({
        ...account,
        userId: docusignUserInfoResult.data.userInfo.sub,
      }))

  return { data: accountsWithUserId, isLoading: !accessToken || docusignUserInfoResult.isLoading }
}

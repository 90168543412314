import React from 'react'
import { AddDocusignIntegrationCard } from '~/client/components/integration/'
import { StepContainer } from '~/client/components/onboarding-modal/util'

export const ConnectIntegrationsStep: React.FC = () => {
  const [clickedIntegrationAdd, setClickedIntegrationAdd] = React.useState(false)
  return (
    <StepContainer
      title='Connect to eSignature'
      subtitle='Automatically add documents to stay organized with zero lift'
      stepCompleted={clickedIntegrationAdd}
      dataTestId='onboarding-connect-integrations-step'
    >
      <AddDocusignIntegrationCard
        isOnboardingIntegrations
        onButtonClick={() => setClickedIntegrationAdd(true)}
      />
    </StepContainer>
  )
}

import type { CryptId } from '@cryptid-module'
import { Box, Center, Group, ScrollArea, Stack, Table, Text } from '@mantine/core'
import type { UseQueryResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { MultiSelectSearchInput } from '~/client/components/multi-select-search-input'
import {
  OrgInfoNameDisplay,
  OrgInfoTypeBadgeDisplay,
  RelationNameDisplay,
  RelationTypeBadgeDisplay,
} from '~/client/components/relation/display'
import { LoadingErrorComp } from '~/client/components/util/error'
import { LinkSwitchWithTooltip } from '~/client/components/util/link-switch-with-tooltip'
import { Pagination } from '~/client/components/util/pagination'
import type { UsePagination } from '~/client/lib/hooks'
import { useDocLinks } from '~/client/lib/hooks/doc-links'
import type { RelationsSearchParams } from '~/client/lib/hooks/search'
import { stringComparisonOptionsFilterFn, useSearchOptions } from '~/client/lib/hooks/search'
import { theme } from '~/client/lib/theme'
import type { EnhancedRelation } from '~/common/enhance'
import { enhanceRelation } from '~/common/enhance'
import type { Paginated } from '~/common/schema'
import type { ZAugmentedRelation, ZRelationTypeValues } from '~/common/schema/relation'

const CorpRow: React.FC = () => {
  const { isLinkedToCorp, setLinkToCorp } = useDocLinks()
  return (
    <Table.Tr>
      <Table.Td>
        <OrgInfoNameDisplay showTooltip />
      </Table.Td>
      <Table.Td>
        <OrgInfoTypeBadgeDisplay />
      </Table.Td>
      <Table.Td>
        <Group justify='flex-end'>
          <LinkSwitchWithTooltip
            isLinked={isLinkedToCorp}
            onClick={() => setLinkToCorp.mutateAsync({ linkValue: !isLinkedToCorp })}
            isLoading={setLinkToCorp.isLoading}
            linkType='corporation'
          />
        </Group>
      </Table.Td>
    </Table.Tr>
  )
}

interface CommonProps {
  onSetLink: (data: { relationCryptId: CryptId; linkValue: boolean }) => Promise<unknown>
  isLinked: (relation: EnhancedRelation) => boolean
  singleLink?: boolean
}

interface RelationRowProps extends CommonProps {
  relation: EnhancedRelation
  isAutofill: boolean
  hasOneLinkedRelation: boolean
}

const RelationRow: React.FC<RelationRowProps> = ({
  relation,
  isLinked,
  isAutofill,
  onSetLink,
  singleLink,
  hasOneLinkedRelation,
}) => {
  const isRelationLinked = isLinked(relation)

  const setLinkMutation = useMutation(() =>
    onSetLink({ linkValue: !isRelationLinked, relationCryptId: relation.cryptId })
  )

  return (
    <Table.Tr>
      <Table.Td>
        <RelationNameDisplay relation={relation} showTooltip isAutofill={isAutofill} />
      </Table.Td>
      <Table.Td>
        <RelationTypeBadgeDisplay type={relation.type} />
      </Table.Td>
      <Table.Td>
        <Group justify='flex-end'>
          <LinkSwitchWithTooltip
            isLinked={isRelationLinked}
            onClick={() => setLinkMutation.mutateAsync()}
            isLoading={setLinkMutation.isLoading}
            linkType='relation'
            unlinkWarningInTooltip={singleLink && hasOneLinkedRelation}
          />
        </Group>
      </Table.Td>
    </Table.Tr>
  )
}

interface RelationsResultsViewProps extends CommonProps {
  queryResult: UseQueryResult<Paginated<ZAugmentedRelation>>
  showCorp: boolean
  autofillCryptIds: CryptId[]
  pagination: UsePagination
  searchParams: RelationsSearchParams
}

const RelationsResultsView: React.FC<RelationsResultsViewProps> = ({
  showCorp,
  queryResult,
  autofillCryptIds,
  pagination,
  searchParams,
  isLinked,
  ...props
}) => {
  const relations = (queryResult.data?.data ?? []).map((relation) => enhanceRelation(relation))
  const noQuery = searchParams.query.length === 0 && searchParams.types.length === 0
  const hasOneLinkedRelation = relations.some(isLinked)

  if (noQuery && !showCorp)
    return (
      <Center>
        <Text size='xl' c='dimmed' mb='lg'>
          Query Needed
        </Text>
      </Center>
    )

  if (pagination.totalItems.count === 0 && !showCorp)
    return (
      <Center>
        <Text size='xl' c='dimmed' mb='lg'>
          No Relations Found
        </Text>
      </Center>
    )

  return (
    <ScrollArea flex={1}>
      <Table striped mb='sm'>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {showCorp && pagination.activePage === 0 && <CorpRow />}
          {relations.map((relation) => (
            <RelationRow
              key={relation.cryptId.idStr}
              relation={relation}
              isAutofill={autofillCryptIds.some((cryptId) => cryptId.equals(relation.cryptId))}
              isLinked={isLinked}
              hasOneLinkedRelation={hasOneLinkedRelation}
              {...props}
            />
          ))}
        </Table.Tbody>
      </Table>
      <Pagination
        styles={{ ml: theme.spacing.xs }}
        {...pagination}
        queryResult={queryResult}
        hideSizeSelector
      />
    </ScrollArea>
  )
}

interface RelationSelectModalProps extends CommonProps {
  allowedTypes: ZRelationTypeValues[]
  defaultQuery: string[]
  allowCorp: boolean
  autofillCryptIds: CryptId[]
  setQuery: (query: string[]) => void
  searchResults: UseQueryResult<Paginated<ZAugmentedRelation>>
  searchParams: RelationsSearchParams
  pagination: UsePagination
}

export const BaseRelationSearchModalContent: React.FC<RelationSelectModalProps> = ({
  allowedTypes,
  defaultQuery,
  allowCorp,
  autofillCryptIds,
  searchResults,
  setQuery,
  searchParams,
  pagination,
  singleLink,
  ...props
}) => {
  const options = useSearchOptions([], allowedTypes, stringComparisonOptionsFilterFn)

  return (
    <Stack w={theme.other.widths.md + 125} h='75vh' gap='lg' align='stretch'>
      <Box>
        <MultiSelectSearchInput
          options={options}
          defaultValue={defaultQuery}
          onChange={(value) => {
            pagination.setActivePage(0)
            setQuery(value)
          }}
          isFetching={searchResults.isFetching}
          multiline
          placeholder='Search all relations'
        />
      </Box>
      <LoadingErrorComp queryResult={searchResults}>
        <RelationsResultsView
          queryResult={searchResults}
          // Don't show the corp when searching
          showCorp={allowCorp && searchParams.query.length === 0}
          autofillCryptIds={autofillCryptIds}
          pagination={pagination}
          searchParams={searchParams}
          singleLink={singleLink}
          {...props}
        />
      </LoadingErrorComp>
    </Stack>
  )
}

import {
  ActionIcon,
  Box,
  Center,
  Group,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core'
import { IconChartPie2 } from '@tabler/icons-react'
import Router from 'next/router'
import React, { type PropsWithChildren } from 'react'
import { useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { ImageWithPlaceholder } from '~/client/components/util/image-with-placeholder'
import { NextLinkOpt } from '~/client/components/util/link'
import { TextWithTooltip } from '~/client/components/util/text-with-tooltip'
import { miniDocBoxShadowHover } from '~/client/lib/css-util.css'
import { useDownloadOrGenerateThumbnail } from '~/client/lib/hooks/s3'
import { theme } from '~/client/lib/theme'
import { docDisplay } from '~/common/doc'
import type { ZAugmentedDoc } from '~/common/schema'

const MiniDocFrame = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<{
    leftIcon: React.ReactNode
    rightIcon?: React.ReactNode
    onClick?: () => void
  }>
>(({ children, leftIcon, rightIcon, onClick }, ref) => {
  return (
    <UnstyledButton onClick={onClick} ref={ref}>
      <Group
        align='stretch'
        style={{
          position: 'relative',
          backgroundColor: 'white',
          overflow: 'clip',
          borderRadius: '4px',
          border: `1px solid ${theme.colors.gray[2]}`,
          flexWrap: 'nowrap',
          gap: 0,
        }}
        className={miniDocBoxShadowHover}
      >
        {leftIcon}
        <Box style={{ flex: 'auto' }}>{children}</Box>
        {rightIcon}
      </Group>
    </UnstyledButton>
  )
})

export const MiniDoc: React.FC<{
  doc: ZAugmentedDoc
  isInactive: boolean
}> = ({ doc, isInactive }) => {
  const openDocDetail = useDocDetailViewStore((state) => state.openModal)
  const imageMargin = 4
  const imageBackground = useMantineTheme().colors.gray[2]
  const thumbResult = useDownloadOrGenerateThumbnail(doc)

  return (
    <Tooltip label={docDisplay(doc)} openDelay={500} position='top'>
      <MiniDocFrame
        onClick={() => openDocDetail({ doc })}
        leftIcon={
          <Box
            style={{
              backgroundColor: imageBackground,
              width: 40 + imageMargin * 2,
            }}
          >
            <ImageWithPlaceholder
              h='100%'
              w='100%'
              imageProps={{
                h: '100%',
                src: thumbResult.objectUrl,
                style: {
                  padding: imageMargin,
                  paddingBottom: 0,
                  height: '100%',
                },
              }}
              placeholder={<Box w='100%' h='100%' bg={imageBackground} />}
            />
          </Box>
        }
      >
        <Group p={8} style={{ gap: 8 }}>
          <NextLinkOpt
            onClick={() => {}}
            truncate
            style={{
              ...(isInactive ? { color: theme.colors.inactive[7] } : {}),
              maxWidth: 130,
              display: 'block',
            }}
            data-testid='mini-doc-anchor'
          >
            {docDisplay(doc, { short: true })}
          </NextLinkOpt>
        </Group>
      </MiniDocFrame>
    </Tooltip>
  )
}

interface MiniObjectProp {
  name: string
  href?: string
  rightIcon?: React.ReactNode
}

export const MiniObject: React.FC<MiniObjectProp> = ({ name, href, rightIcon }) => {
  return (
    <MiniDocFrame
      onClick={() => href && Router.push(href)}
      leftIcon={
        <Center
          style={{
            backgroundColor: theme.colors.gray[2],
            flex: 'none',
            width: 48,
          }}
        >
          <ActionIcon component='span'>
            <IconChartPie2 />
          </ActionIcon>
        </Center>
      }
      rightIcon={rightIcon}
    >
      <NextLinkOpt style={{ display: 'block' }} p={8} onClick={() => {}}>
        <TextWithTooltip style={{ maxWidth: 130 }}>{name}</TextWithTooltip>
      </NextLinkOpt>
    </MiniDocFrame>
  )
}

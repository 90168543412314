import { useRouter } from 'next/router'
import React from 'react'
import { useCorpCryptId } from '~/client/lib/hooks'

interface UseSearchQueriesRtn {
  queries: string[]
  setQueries: (_: string[]) => Promise<void>
}

const parseQueries = (q: string[] | string | undefined): string[] => {
  if (!q) return []
  if (Array.isArray(q)) return q[0]?.split(';') ?? []
  return q.split(';')
}

/**
 * Manages the URL search queries in search?q=...
 * Because the state is stored in the URL, this can
 * manage state without being an unstated-next container
 * @returns
 */
export const useSearchQueries = (): UseSearchQueriesRtn => {
  const router = useRouter()
  const { mkCurrentCorpRoute } = useCorpCryptId()
  const { q } = router.query
  const queries = parseQueries(q)

  const setQueries = React.useCallback(
    async (newQueries: string[]) => {
      const newQ = newQueries.join(';')
      // Encoding to avoid unexpected behavior with chars like & and #
      const newRoute = mkCurrentCorpRoute('search', `?q=${encodeURIComponent(newQ)}&searchBarFocus`)
      if (!router.pathname.endsWith('/search') && newQ === '') return
      // Prevent infinite route changes
      if (newQ === q) return
      await router.push(newRoute)
    },
    [mkCurrentCorpRoute, q, router]
  )
  return {
    queries,
    setQueries,
  }
}
export type UseSearchQueries = typeof useSearchQueries

export const usePrefillEmailQueryParam = (): string | undefined => {
  const router = useRouter()
  const { email } = router.query
  return email instanceof Array ? email[0] : email
}

export const useSearchFocusQueryParam = (): boolean => {
  const router = useRouter()
  const { searchBarFocus } = router.query
  return searchBarFocus !== undefined
}

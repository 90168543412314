import type { CryptId } from '@cryptid-module'
import type { GetInputProps } from '@mantine/form/lib/types'
import type { QueryClient } from '@tanstack/react-query'
import type { ZAugmentedCorp, ZAugmentedDoc } from '~/common/schema'
import type { ZAugmentedRelation } from '~/common/schema/relation'
import { bufferToBase64 } from '~/common/util'

/**
 * This method is only useful for Firefox because that browser doesn't support
 * copying HTML with the new ClipboardAPI. It calls the deprecated document.execCommand
 * https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/write#browser_compatibility
 * @param element
 */
const legacyCopyToClipboard = (element: HTMLElement) => {
  // https://stackoverflow.com/a/59462028
  // removes current selection if any
  window.getSelection()?.removeAllRanges()
  const range = document.createRange()
  // selects contents of element
  range.selectNode(element)
  // adds selection
  window.getSelection()?.addRange(range)
  // eslint-disable-next-line deprecation/deprecation
  document.execCommand('copy')
  window.getSelection()?.removeAllRanges()
}

/** Copies the content of a rendered component to the clipBoard */
export const copyRenderedElementToClipboard = async (element: HTMLElement): Promise<void> => {
  if (typeof window === 'undefined') {
    throw new Error('copyRenderedElementToClipboard should only be called in an event handler')
  }
  // This will only be true for Firefox, which doesn't support the
  // new method of copying html to clipboard
  if (typeof ClipboardItem === 'undefined') return legacyCopyToClipboard(element)

  const clipboardItem = new ClipboardItem({
    'text/html': new Blob([element.innerHTML], { type: 'text/html' }),
    'text/plain': new Blob([element.innerText], { type: 'text/plain' }),
  })
  await navigator.clipboard.write([clipboardItem])
}

export const mkSafeFileName = (str: string): string => str.replace(/[^a-zA-Z0-9-.]/g, '_')

/** Adds the domain to a data's URL for sharing. This should only be used on the client */
export const getShareUrl = (item: ZAugmentedRelation | ZAugmentedCorp | ZAugmentedDoc): string => {
  if (typeof window === 'undefined') {
    throw new Error('getShareUrl can only be called on the client')
  }

  // If item is ZAugmentedCorp
  if (!('url' in item)) return `${window.location.host}corp/${item.cryptId.idStr}/dashboard`
  return `${window.location.host}${item.url}`
}

export const dedupeDates = (dates: Date[]): Date[] => {
  // dedupe with Map instead of set because we need to preserve the objects
  const dedupeMap = new Map<string, Date>(dates.map((date) => [date.toISOString(), date]))

  return Array.from(dedupeMap.values())
}

export const optimisticSetLink = <T extends ZAugmentedCorp | ZAugmentedRelation>(
  item: T,
  docCryptId: CryptId,
  link: boolean
): T => {
  return link
    ? {
        ...item,
        docCryptIds: [...item.docCryptIds, docCryptId],
        // We don't have the full doc to append to docOpts
      }
    : {
        ...item,
        docCryptIds: item.docCryptIds.filter((cryptId) => !cryptId.equals(docCryptId)),
        docOpts: item.docOpts.filter((doc) => !doc || !doc.cryptId.equals(docCryptId)),
      }
}

/** Utility that ensures the type passed is of type never. This is useful to ensure
 * exhaustiveness was checked. Use this on the `default` case of `switch`
 * statements as a workaround for the `array-callback-return` eslint rule, when
 * using them inside `.map()`.
 *
 * This is a known eslint issue https://github.com/typescript-eslint/typescript-eslint/issues/2841
 * */
export const exhaustivenessSafetyCheck = (never: never): never => {
  throw new Error(`Unknown value ${never}`)
}

export const computeSHA256 = async (file: File): Promise<string> => {
  const sha256 = await crypto.subtle.digest('SHA-256', await file.arrayBuffer())
  return bufferToBase64(sha256)
}

export const invalidateQueries = async (queryClient: QueryClient): Promise<void> => {
  return queryClient.invalidateQueries({
    predicate: (query) => {
      return !query.meta?.noGlobalInvalidation
    },
  })
}

// `onChange` is called with `string` (not number) | null in Mantine 7
// <NumberInput/>, so we need to convert it into number
export const mkFormNumberInputProps = <FormValues>(
  inputProps: ReturnType<GetInputProps<FormValues>>
): ReturnType<GetInputProps<FormValues>> => {
  const { onChange, ...rest } = inputProps
  return {
    onChange: (value: number | string) => {
      const parsedValue = typeof value === 'number' ? value : parseFloat(value)
      if (Number.isNaN(parsedValue)) {
        onChange(undefined)
        return
      }
      onChange(parsedValue)
    },
    ...rest,
  }
}

import type { TextProps } from '@mantine/core'
import { Box, Group, Stack, Text } from '@mantine/core'
import Router from 'next/router'
import pluralize from 'pluralize'
import React from 'react'
import { LoadingErrorComp } from '~/client/components/util/error'
import { LoadMoreInfiniteQuery } from '~/client/components/util/load-more-infinite-query'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import { nextPageParamOpts } from '~/client/lib/hooks/query'
import { exhaustivenessSafetyCheck } from '~/client/lib/util'
import { AddIntegrationCard } from './add-integration-card'
import { DocusignRemoveIntegrationCard } from './docusign'
import { IntegrationCardSkeleton } from './integration-card'
import { integrationModalQueryParameter } from './modal-hooks'

const IntegrationQueryStatus: React.FC<{ integrationsAmount?: number }> = ({
  integrationsAmount,
}) => {
  const { mkCurrentCorpRoute } = useCorpCryptId()
  React.useEffect(() => {
    const { [integrationModalQueryParameter]: integrationModal } = Router.query
    // We should make sure the integrationModal query parameter is not present
    // because the user is redirected to the `/integrations` page upon adding the
    // first integration account, and we should prevent the user keep
    // redirecting to `/integrations/add` page
    if (integrationsAmount === 0 && !integrationModal) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Router.replace(mkCurrentCorpRoute('integrations', 'add?isFirstIntegration'))
    }
  }, [integrationsAmount, mkCurrentCorpRoute])
  const props: TextProps = { color: 'dimmed', h: '1rem' }
  // add empty box to prevent layout shift
  if (integrationsAmount === undefined) return <Box {...props}> </Box>
  if (integrationsAmount === 0) return <Text {...props}> No integrations found </Text>
  return (
    <Text {...props}>
      {' '}
      {`${integrationsAmount} ${pluralize('integration', integrationsAmount)} found`}
    </Text>
  )
}

export const IntegrationGrid: React.FC = () => {
  const integrationResult = hooks
    .trpc()
    .integration.list.useInfiniteQueryWithCorp(
      { direction: 1, limit: 16 },
      { keepPreviousData: true, ...nextPageParamOpts() }
    )

  const integrations = React.useMemo(() => {
    const arr = integrationResult.data?.pages.flatMap((page) => page.data)
    if (!arr) return []
    return arr
  }, [integrationResult.data])

  return (
    <Stack>
      <IntegrationQueryStatus
        integrationsAmount={integrationResult.isLoading ? undefined : integrations.length}
      />
      <Group gap='xl'>
        <AddIntegrationCard />
        <LoadingErrorComp
          queryResult={integrationResult}
          skeleton={
            <>
              {new Array(2).fill(null).map((_, i) => (
                <IntegrationCardSkeleton key={i} />
              ))}
            </>
          }
        >
          {integrations.map((integration) => {
            const { type } = integration
            switch (type) {
              case 'docusign':
                return (
                  <DocusignRemoveIntegrationCard key={integration.cryptId.idStr} {...integration} />
                )
              case 'adobeSign':
                throw new Error('Not implemented')
              default:
                return exhaustivenessSafetyCheck(type)
            }
          })}
        </LoadingErrorComp>
      </Group>
      {integrationResult.hasNextPage && <LoadMoreInfiniteQuery query={integrationResult} />}
    </Stack>
  )
}

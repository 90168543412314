import {
  ActionIcon,
  Box,
  CopyButton,
  Group,
  Skeleton,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core'
import { useDebouncedState, useFocusTrap } from '@mantine/hooks'
import { IconCheck, IconCopy, IconExternalLink } from '@tabler/icons-react'
import Router from 'next/router'
import React from 'react'
import { MultiSelectSearchInput } from '~/client/components/multi-select-search-input'
import { nestedClickArea } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { metadataRowHoverClasses } from '~/client/lib/css-util.css'
import { hooks } from '~/client/lib/hooks'
import type { ItemWithSearch, QuickAnswerData } from '~/client/lib/hooks/search'
import {
  extractQuickAnswersValuePrefix,
  isQuickAnswerOptionValue,
  stringComparisonOptionsFilterFn,
  useDocSearchState,
  useQuickAnswerSearch,
  useSearchOptions,
} from '~/client/lib/hooks/search'
import { useSearchFocusQueryParam } from '~/client/lib/query-params'
import { theme } from '~/client/lib/theme'
import { Random } from '~/common/random'
import { ZAugmentedDoc } from '~/common/schema'
import { ZAugmentedRelation } from '~/common/schema/relation'

const random = new Random()

const ValueComp: React.FC<{ value: string }> = ({ value }) => (
  <CopyButton value={value} timeout={2000}>
    {({ copied, copy }) => (
      <Tooltip
        label={copied ? 'Copied!' : 'Copy to clipboard'}
        color={copied ? 'primary' : undefined}
      >
        <Group
          className={`${metadataRowHoverClasses.hover} ${nestedClickArea.cssClass}`}
          pos='relative'
          pl={6}
          gap={2}
          onClick={copy}
        >
          <Box
            pos='absolute'
            left={0}
            right={0}
            top={0}
            bottom={0}
            className={metadataRowHoverClasses.appear}
            style={() => ({
              border: `1px solid ${theme.colors.primary[7]}`,
              color: theme.colors.primary[7],
              borderRadius: 4,
            })}
          />
          <Text fz='sm' c='primary'>
            {value}
          </Text>
          <ActionIcon
            component='span'
            color={copied ? 'go' : 'primary'}
            className={metadataRowHoverClasses.appear}
          >
            {copied ? <IconCheck /> : <IconCopy />}
          </ActionIcon>
        </Group>
      </Tooltip>
    )}
  </CopyButton>
)

const ComboboxOptionDisplay: React.FC<QuickAnswerData> = ({
  display,
  label,
  sourceName,
  queryResult,
}) => {
  const innerDisplay = !!display && <ValueComp value={display} />
  return (
    <Group>
      <Text fw={500} fz='sm'>
        <b>{label}</b> on {sourceName}
      </Text>
      <Group gap='xs'>
        {queryResult ? (
          <LoadingErrorComp
            variant='tooltip'
            loaderSize='xs'
            queryResult={queryResult}
            errorIconSize='xs'
            skeleton={<Skeleton height={18} width={`${random.randomInt(20) + 30}%`} />}
          >
            {innerDisplay}
          </LoadingErrorComp>
        ) : (
          innerDisplay
        )}
      </Group>
      <ThemeIcon color='primary' size='sm'>
        <IconExternalLink />
      </ThemeIcon>
    </Group>
  )
}

export const SearchInput: React.FC = () => {
  const { queries, setQueries } = hooks.useSearchQueries()
  const [debouncedSelectedValues, setDebouncedSelectedValues] = useDocSearchState(queries)
  const searchFocus = useSearchFocusQueryParam()
  const focusTrapRef = useFocusTrap(searchFocus)

  const [debouncedSearch, setDebouncedSearch] = useDebouncedState('', 350)
  const fullSearchBarContents = [...debouncedSelectedValues, debouncedSearch]
  const { data, searchResults: quickAnswersQuery } = useQuickAnswerSearch({
    query: fullSearchBarContents,
    //Avoid too much layout shift in the search bar
    keepPreviousData: true,
  })
  const filterFn = React.useCallback((search: string, item: ItemWithSearch): boolean => {
    // Quick answers are already filtered and ordered with vector search
    if (isQuickAnswerOptionValue(item.value)) return true
    return stringComparisonOptionsFilterFn(search, item)
  }, [])
  const docSearchOptions = useSearchOptions(
    queries,
    [...ZAugmentedDoc.types, ...ZAugmentedRelation.types],
    filterFn
  )

  // navigate to search page
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setQueries(debouncedSelectedValues)
  }, [setQueries, debouncedSelectedValues])

  return (
    <form>
      <Group maw={theme.other.widths.md} justify='space-between' wrap='nowrap'>
        <MultiSelectSearchInput
          options={{
            ...docSearchOptions,
            data: [
              {
                group: 'Quick Answers',
                items: data.map((quickAnswer) => ({
                  value: quickAnswer.key,
                  label: quickAnswer.label,
                  display: <ComboboxOptionDisplay {...quickAnswer} key={quickAnswer.key} />,
                })),
              },
              ...docSearchOptions.data,
            ],
          }}
          defaultValue={queries}
          onChange={setDebouncedSelectedValues}
          onOptionSubmit={async (value) => {
            const quickAnswersValue = extractQuickAnswersValuePrefix(value)
            if (quickAnswersValue && quickAnswersValue.url) await Router.push(quickAnswersValue.url)
          }}
          placeholder='Search all documents'
          shouldSetValue={(value: string) => !isQuickAnswerOptionValue(value)}
          ref={focusTrapRef}
          onSearchChange={setDebouncedSearch}
          isFetching={quickAnswersQuery.isFetching}
        />
      </Group>
    </form>
  )
}

import type { CryptId } from '@cryptid-module'
import { z } from 'zod'
import { AugmentedMetadataDate, ZAugmentedDoc } from '~/common/schema'
import { ZCryptId } from '~/common/schema/util'
import { withDisplaySchema } from '~/common/schema/with-display-schema'
import type { OmitUnion } from '~/common/util-types'
import type { ZMongoDoc } from '~/server/mongo/schema/doc'

export const ZAugmentedRelationBase = z.object({
  index: z.number(),
  startDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Start Date'),
  endDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'End Date'),
  cryptId: ZCryptId,
  corpCryptId: ZCryptId,
  docCryptIds: ZCryptId.array(),
  url: z.string(),
  docOpts: ZAugmentedDoc.optional().array(),
  createdBy: z.string().email().optional(),
})

export interface ZAugmentedRelationBase extends z.infer<typeof ZAugmentedRelationBase> {}

export const ZUpdateRelationBase = z.object({
  startDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Start Date'),
  endDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'End Date'),
  corpCryptId: ZCryptId,
  docCryptIds: ZCryptId.array(),
})

export interface ZUpdateRelationBase extends z.infer<typeof ZUpdateRelationBase> {}

export const isMetadata = (data: unknown): boolean => {
  // Check for properties that informs that the given argument is a Metadata
  const schema = z.union([
    z.object({ __type: z.literal('Metadata') }),
    z.object({ _def: z.object({ innerType: z.object({ __type: z.literal('Metadata') }) }) }),
  ])
  return schema.safeParse(data).success
}

export const getMetadataKeys = (shape: z.ZodRawShape): string[] =>
  Object.entries(shape)
    .filter(([_, value]) => isMetadata(value))
    .map(([key, _]) => key)

export type DisplayExcludeFields =
  | 'cryptId'
  | 'corpCryptId'
  | 'docCryptIds'
  | 'docOpts'
  | 'url'
  | 'index'

// Helper functions
export namespace util {
  export type OmitSourceCryptId<O> = {
    [K in keyof O]: OmitUnion<O[K], 'sourceCryptId'>
  }

  interface AugmentedRelationSchemaType<T extends z.ZodRawShape>
    extends Omit<
      z.ZodObject<z.objectUtil.extendShape<typeof ZAugmentedRelationBase.shape, T>>,
      'deepPartial'
    > {}

  interface UpdateRelationSchemaType<T extends z.ZodRawShape>
    extends Omit<
      z.ZodObject<z.objectUtil.extendShape<typeof ZUpdateRelationBase.shape, T>>,
      'deepPartial'
    > {}

  interface AugmentedAnnotationType<
    AugmentedShape extends { type: z.ZodLiteral<string> },
    RequiredTypes extends readonly ZMongoDoc['type'][],
    OptionalTypes extends readonly ZMongoDoc['type'][],
  > {
    display: string
    requiredTypes: RequiredTypes
    optionalTypes: OptionalTypes
    displayFn: (
      /** This object omits some of the fields that are not needed to generate the display of a
       * relation. This makes the display function compatible with mongo and augmented
       * relations  */
      _: OmitSourceCryptId<
        Omit<z.infer<AugmentedRelationSchemaType<AugmentedShape>>, DisplayExcludeFields>
      >
    ) => string
    /** Pulls out the identifier for this type of relation (usually startDate or party.name). If no identifying feature, then `undefined`. */
    identifyingField: 'startDate' | 'party.name' | undefined
    tooltipContentFn?: (
      obj: z.infer<AugmentedRelationSchemaType<AugmentedShape>>,
      shape: AugmentedRelationSchemaType<AugmentedShape>['shape']
    ) => [string, string | null | undefined][]
  }

  interface AugmentedSchemaRtn<
    UpdateShape extends { type: z.ZodLiteral<string> },
    AugmentedShape extends { type: UpdateShape['type'] },
    RequiredTypes extends readonly ZMongoDoc['type'][],
    OptionalTypes extends readonly ZMongoDoc['type'][],
    T extends string = UpdateShape extends { type: z.ZodLiteral<infer L> } ? L : never,
  > extends AugmentedAnnotationType<AugmentedShape, RequiredTypes, OptionalTypes> {
    type: T
    empty: (corpCryptId: CryptId) => z.infer<UpdateRelationSchemaType<UpdateShape>>
    supportedTypes: (RequiredTypes[number] | OptionalTypes[number])[]
  }

  interface CreateSchemasRtn<
    /**
     * zod type when passing updates from client to server
     */
    UpdateShape extends { type: z.ZodLiteral<string> },
    /**
     * zod type when passed from server to client
     */
    AugmentedShape extends { type: UpdateShape['type'] },
    /**
     * Document types that are required for this relation
     */
    RequiredTypes extends readonly ZMongoDoc['type'][],
    /**
     * Document types that are optional for this relation.  Only OptionalTypes
     * and RequiredTypes can be used in the relation.
     */
    OptionalTypes extends readonly ZMongoDoc['type'][],
    T extends string = UpdateShape extends { type: z.ZodLiteral<infer L> } ? L : never,
  > {
    ZUpdate: UpdateRelationSchemaType<UpdateShape>
    // eslint-disable-next-line custom-rules/prefer-extends-to-type-intersection
    ZAugmented: AugmentedRelationSchemaType<AugmentedShape> &
      AugmentedSchemaRtn<UpdateShape, AugmentedShape, RequiredTypes, OptionalTypes, T>
  }

  /**
   * `createSchemas` accepts two shapes (`mongoShape` and `augmentedShape`)
   * because some relation schemas have different fields for their mongo and
   * augmented schemas. For example `ZPreferred` has `fundraisingId` on its
   * mongo schema and `fundraisingCryptId` on its augmented schema. We currently
   * have only two relation schemas with this issue but eventually when we
   * migrate `sourceId` to `sourceCryptId`, almost every relation would have
   * different values for it's mongo shape and augmented shape.
   * @param shapes
   * @param options
   * @returns
   */
  export const createSchemas = <
    UpdateShape extends { type: z.ZodLiteral<string> },
    AugmentedShape extends { type: UpdateShape['type'] },
    RequiredTypes extends readonly ZMongoDoc['type'][],
    OptionalTypes extends readonly ZMongoDoc['type'][],
    T extends string = UpdateShape extends { type: z.ZodLiteral<infer L> } ? L : never,
  >(
    {
      updateShape,
      augmentedShape,
      defaultValue,
    }: {
      updateShape: UpdateShape
      augmentedShape: AugmentedShape
      defaultValue: Omit<
        z.infer<UpdateRelationSchemaType<UpdateShape>>,
        'corpCryptId' | 'type' | 'docCryptIds'
      >
    },
    {
      requiredTypes,
      optionalTypes,
      display,
      displayFn,
      identifyingField,
      tooltipContentFn,
    }: AugmentedAnnotationType<AugmentedShape, RequiredTypes, OptionalTypes>
  ): CreateSchemasRtn<UpdateShape, AugmentedShape, RequiredTypes, OptionalTypes, T> => {
    const ZUpdate = ZUpdateRelationBase.extend(updateShape)
    const ZAugmented = ZAugmentedRelationBase.extend(augmentedShape)

    return {
      ZUpdate,
      ZAugmented: Object.assign(ZAugmented, {
        type: augmentedShape.type.value as T,
        display,
        requiredTypes,
        optionalTypes,
        supportedTypes: [...requiredTypes, ...optionalTypes],
        displayFn,
        identifyingField,
        metadata: getMetadataKeys(augmentedShape),
        empty: (corpCryptId: CryptId) =>
          ({
            ...defaultValue,
            type: augmentedShape.type.value as T,
            corpCryptId,
            docCryptIds: [],
          }) as unknown as z.infer<UpdateRelationSchemaType<UpdateShape>>,
        tooltipContentFn,
      }),
    }
  }

  export const annotateDisplay = <R extends object>(
    obj: R,
    display: string
  ): R & { display: string } =>
    Object.assign<R, { display: string }>(obj, {
      display,
    })
}

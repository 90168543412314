import type { TextItem } from 'pdfjs-dist/types/src/display/api'
import { getPdfDocument } from '~/common/pdfjs'
import type { ZAugmentedDocWithHighlights } from '~/common/schema'

export const getHighlights = (
  doc: Pick<ZAugmentedDocWithHighlights, 'highlights'> | undefined
): string[] | undefined =>
  doc?.highlights?.flatMap((highlight) =>
    highlight.texts
      .filter((text) => text.type === 'hit')
      .map((text) => text.value.toLowerCase().trim())
  )

export const highlightTextsInPdf = async (
  pdfBlob: Blob,
  textsToHighlight: string[]
): Promise<Blob> => {
  const [{ PDFDocument, rgb }, pdfBuffer] = await Promise.all([
    import('pdf-lib'),
    pdfBlob.arrayBuffer(),
  ])

  const [pdfjsDoc, pdfLibDoc] = await Promise.all([
    getPdfDocument({ data: new Uint8Array(pdfBuffer) }),
    PDFDocument.load(pdfBuffer),
  ])

  await Promise.all(
    pdfLibDoc.getPages().map(async (pdfLibPage, pageIndex) => {
      const pdfjsPage = await pdfjsDoc.getPage(pageIndex + 1)
      const textContent = await pdfjsPage.getTextContent()

      // eslint-disable-next-line security/detect-non-literal-regexp
      const regex = new RegExp(textsToHighlight.join('|'), 'gi')

      textContent.items
        .filter((item): item is TextItem => 'str' in item)
        .forEach(({ transform, width, height, str }) => {
          const x = transform[4] // Horizontal translation
          const y = transform[5] // Vertical translation
          const matches = Array.from(str.matchAll(regex))

          matches.forEach((match) => {
            const matchString = match[0]
            const startIndex = match.index ?? 0
            const widthPerChar = width / str.length
            const substringWidth = widthPerChar * matchString.length
            const substringX = x + widthPerChar * startIndex

            pdfLibPage.drawRectangle({
              // adjust x so that the rectangle has a little padding around the
              // text on the x axis
              x: substringX - 2,
              // adjust y so that the rectangle has a little padding around the
              // text on the y axis
              y: y - 2,
              // adjust width so that the rectangle has a little padding around the
              // text on the x axis
              width: substringWidth + 4,
              height: height + 1,
              color: rgb(1.0, 0.843, 0.0),
              opacity: 0.5,
            })
          })
        })
    })
  )

  return new Blob([await pdfLibDoc.save()], { type: 'application/pdf' })
}

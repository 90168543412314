import type { CryptId } from '@cryptid-module'
import React, { useMemo } from 'react'
import { sortBy, uniq } from 'underscore'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'
import { dedupeDates } from '~/client/lib/util'
import type { DataApi } from '~/common/data-api'
import toUTCDate from '~/common/date-utc'
import type { DocAutofill } from '~/common/enhance'
import type { ZParty } from '~/common/schema'
import { ZAugmentedDoc, mkSortDate } from '~/common/schema'

export interface SuggestionLoadingStates {
  types: boolean
  dates: boolean
  emails: boolean
  titles: boolean
  parties: boolean
}

type SuggestionWithoutSource = {
  [K in keyof DataApi.Suggestions]: Omit<DataApi.Suggestions[K][number], 'source'>[]
}

interface UseSuggestions {
  data?: SuggestionWithoutSource
  loadingStates: SuggestionLoadingStates
}

export const useSuggestions = (
  docCryptId: CryptId | undefined,
  // This is Required to avoid cases where we add a new suggestion type
  // but forget to update usages
  options: Required<DataApi.SuggestionQuery>
): UseSuggestions => {
  const { data: auth } = useCurrentCorpAuth()
  const queryParams = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    docCryptId: docCryptId!,
  }

  const cryptIdDefined = !!docCryptId
  const isInvestor = auth?.level === 'investor'

  const typesEnabled = cryptIdDefined && !!options.types && !isInvestor
  const datesEnabled = cryptIdDefined && !!options.dates && !isInvestor
  const emailsEnabled = cryptIdDefined && !!options.emails && !isInvestor
  const titlesEnabled = cryptIdDefined && !!options.titles && !isInvestor
  const partiesEnabled = cryptIdDefined && !!options.parties && !isInvestor

  // We do not want to invalidate these queries after a document gets saved as
  // the results will not change
  const queryOptions = {
    // store these up to a day or until browser refresh
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
    meta: { noGlobalInvalidation: true },
  } as const

  const typesQuery = hooks.trpc().doc.suggestions.types.byUrl.useQueryWithCorp(queryParams, {
    enabled: typesEnabled,
    ...queryOptions,
  })
  const datesQuery = hooks.trpc().doc.suggestions.dates.byUrl.useQueryWithCorp(queryParams, {
    enabled: datesEnabled,
    ...queryOptions,
  })
  const emailsQuery = hooks.trpc().doc.suggestions.emails.byUrl.useQueryWithCorp(queryParams, {
    enabled: emailsEnabled,
    ...queryOptions,
  })
  const titlesQuery = hooks.trpc().doc.suggestions.titles.byUrl.useQueryWithCorp(queryParams, {
    enabled: titlesEnabled,
    ...queryOptions,
  })
  const partiesQuery = hooks.trpc().doc.suggestions.parties.byUrl.useQueryWithCorp(queryParams, {
    enabled: partiesEnabled,
    ...queryOptions,
  })

  const data = useMemo(
    () => ({
      types: typesQuery.data ?? [],
      dates: datesQuery.data ?? [],
      emails: emailsQuery.data ?? [],
      titles: titlesQuery.data ?? [],
      parties: partiesQuery.data ?? [],
    }),
    [typesQuery.data, datesQuery.data, emailsQuery.data, titlesQuery.data, partiesQuery.data]
  )

  const loadingStates = useMemo(
    () => ({
      // We have to check if the query is enabled otherwise it will load indefinitely
      types: typesEnabled && typesQuery.isLoading,
      dates: datesEnabled && datesQuery.isLoading,
      emails: emailsEnabled && emailsQuery.isLoading,
      titles: titlesEnabled && titlesQuery.isLoading,
      parties: partiesEnabled && partiesQuery.isLoading,
    }),
    [
      typesQuery.isLoading,
      datesQuery.isLoading,
      emailsQuery.isLoading,
      titlesQuery.isLoading,
      partiesQuery.isLoading,
      typesEnabled,
      datesEnabled,
      emailsEnabled,
      titlesEnabled,
      partiesEnabled,
    ]
  )

  return { data, loadingStates }
}

interface UseAutofill {
  autofill?: DocAutofill
  autofillWithHiddenSuggestions?: DocAutofill
  suggestionsLoadingStates: UseSuggestions['loadingStates']
}

const mkAutofill = (
  data: SuggestionWithoutSource | undefined,
  initialAutofill: DocAutofill | undefined,
  docTypesFilter: (type: ZAugmentedDoc['type']) => boolean
): DocAutofill | undefined => {
  if (!data) return initialAutofill

  const mkDateAutofill = (suggestion: SuggestionWithoutSource) => {
    const previousAutofill = initialAutofill?.startDates ?? []
    const suggestionStartDates = suggestion.dates.map((date) => toUTCDate(new Date(date.value)))

    return dedupeDates([...previousAutofill, ...suggestionStartDates]).sort(mkSortDate())
  }

  const mkTypesAutofill = (suggestion: SuggestionWithoutSource): ZAugmentedDoc['type'][] =>
    suggestion.types
      .map((item) => item.value)
      .filter(ZAugmentedDoc.isType)
      .filter(docTypesFilter)

  const mkEmailAutofill = (suggestions: SuggestionWithoutSource): string[] => {
    const initialEmails = initialAutofill?.emails ?? []
    return uniq([...suggestions.emails.map((e) => e.value), ...initialEmails])
  }

  const mkTitleAutofill = (suggestion: SuggestionWithoutSource): string[] =>
    suggestion.titles.map((item) => item.value)

  const mkPartyAutofill = (suggestion: SuggestionWithoutSource): ZParty[] => {
    const mlSuggestions = suggestion.parties.map((item) => ({ name: item.value }))
    const linkedSuggestions = initialAutofill?.parties ?? []

    // Case insensitive dedupe
    return uniq([...linkedSuggestions, ...mlSuggestions], (item) => item.name.toLowerCase())
  }

  return {
    parties: mkPartyAutofill(data),
    emails: mkEmailAutofill(data),
    startDates: mkDateAutofill(data),
    types: mkTypesAutofill(data),
    titles: mkTitleAutofill(data),
  }
}

export const useAutofill = (
  initialAutofill: DocAutofill | undefined,
  docCryptId: CryptId | undefined,
  options: Required<DataApi.SuggestionQuery>,
  docTypesFilter: (type: ZAugmentedDoc['type']) => boolean
): UseAutofill => {
  const { data, loadingStates } = useSuggestions(docCryptId, options)
  const autofillWithHiddenSuggestions = React.useMemo(
    () =>
      data &&
      mkAutofill(
        {
          ...data,
          // We have to filter types here (using a lower threshold) because the data endpoints
          // returns all types (even if they have a probability close to 0)
          types: data.types.filter((type) => type.prob > 0.05),
        },
        initialAutofill,
        docTypesFilter
      ),
    [data, initialAutofill, docTypesFilter]
  )

  const autofill = React.useMemo(
    () =>
      data &&
      mkAutofill(
        {
          types: data.types.filter((type) => type.prob > 0.1),
          // Item should already be sorted, just making sure to avoid potential bugs
          parties: sortBy(data.parties, (item) => -item.prob)
            .slice(0, 2)
            .filter((item) => item.prob > 0.1),
          titles: sortBy(data.titles, (item) => -item.prob)
            .slice(0, 2)
            .filter((item) => item.prob > 0.1),
          dates: data.dates.slice(0, 2),
          emails: data.emails.slice(0, 5),
        },
        initialAutofill,
        docTypesFilter
      ),
    [data, initialAutofill, docTypesFilter]
  )

  return {
    autofill,
    suggestionsLoadingStates: loadingStates,
    autofillWithHiddenSuggestions,
  }
}

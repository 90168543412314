import type { CryptId } from '@cryptid-module'
import React from 'react'
import { BaseModal, BaseRelationSearchModalContent } from '~/client/components/modals'
import { zIndex } from '~/client/components/z-index'
import { usePagination } from '~/client/lib/hooks'
import { mkTypeOptionValue, useRelationSearch } from '~/client/lib/hooks/search'
import type { ZAugmentedRelation, ZRelationTypeValues } from '~/common/schema/relation'

interface EditableRelationModalProps {
  type: ZRelationTypeValues
  opened: boolean
  onClose: () => void
  isLinked: (relation: ZAugmentedRelation) => boolean
  update: (cryptId?: CryptId | null) => Promise<unknown>
}

const EditableRelationModalContent: React.FC<
  Omit<EditableRelationModalProps, 'opened' | 'onClose'>
> = ({ type, isLinked, update }) => {
  const allowedTypes = [type]
  const pagination = usePagination()
  const defaultQuery = allowedTypes.map(mkTypeOptionValue)
  const { setQuery, searchResults, searchParams } = useRelationSearch({
    queries: defaultQuery,
    ...pagination,
  })
  if (searchResults.data) pagination.setTotalItems(searchResults.data.count)

  return (
    <BaseRelationSearchModalContent
      defaultQuery={defaultQuery}
      onSetLink={({ relationCryptId, linkValue }) => update(linkValue ? relationCryptId : null)}
      allowedTypes={allowedTypes}
      searchParams={searchParams}
      setQuery={setQuery}
      searchResults={searchResults}
      autofillCryptIds={[]}
      isLinked={isLinked}
      allowCorp={false}
      pagination={pagination}
      singleLink
    />
  )
}

export const EditableRelationModal: React.FC<EditableRelationModalProps> = ({
  opened,
  onClose,
  ...props
}) => {
  return (
    <BaseModal opened={opened} onClose={onClose} title='Link Relation' zIndex={zIndex.modal}>
      {/* Needs to be a separate component inside BaseModal because Mantine takes care
      of resetting components' states when the modal is closed*/}
      <EditableRelationModalContent {...props} />
    </BaseModal>
  )
}

import { Button, Group, NumberInput, Radio, Stack, Text, ThemeIcon } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useDebouncedState } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconPageBreak } from '@tabler/icons-react'
import type { PDFDocument } from 'pdf-lib'
import React from 'react'
import { z } from 'zod'
import {
  type DisplayAndLinkOptions,
  type ZDocForm,
  useDocDetailState,
} from '~/client/components/doc-detail/state'
import { DocTypesSelect } from '~/client/components/doc-detail/term/doc-types-select'
import { ExtractPagesPreview } from '~/client/components/doc-detail/term/extract-preview'
import { RelationTooltip } from '~/client/components/doc-detail/term/relation-tooltip'
import { NextLinkOpt } from '~/client/components/util'
import { ErrorDisplayComp, LoadingErrorComp } from '~/client/components/util/error'
import {
  type ExtractOptions,
  useExtractAndUploadMutation,
  useLoadAndCheckPdfLibDocument,
} from '~/client/lib/hooks/extract-pages'
import { mkFormNumberInputProps } from '~/client/lib/util'
import type { ZLinkOptions } from '~/common/schema'
import { ZDocType, docTypeStr } from '~/common/schema'

const ZExtractForm = z.object({
  mode: z.enum(['single', 'range', 'rest']),
  firstPage: z.number().min(1),
  lastPage: z.number().min(1),
  target: z.enum(['processing', 'link']),
  docType: ZDocType,
})
type ZExtractForm = z.infer<typeof ZExtractForm>

const preparePages = ({ mode, firstPage, lastPage }: ZExtractForm) => {
  switch (mode) {
    case 'single':
      return { firstPage, lastPage: firstPage, pageRangeStr: `Page ${firstPage}` }
    case 'rest':
      return { firstPage, pageRangeStr: `Pages ${firstPage} to last` }
    case 'range':
      return { firstPage, lastPage, pageRangeStr: `Pages ${firstPage} to ${lastPage}` }
  }
}

const prepareDocType = ({ target, docType }: ZExtractForm) => {
  return target === 'processing' ? 'PROCESSING' : docType
}

const prepareDocTitle = (
  pageRangeStr: string,
  { title, type }: Pick<ZDocForm, 'title' | 'type'>
) => {
  return `${pageRangeStr} from ${title || docTypeStr(type)}`
}

const ExtractLinkLabel: React.FC<{ linkOptions?: DisplayAndLinkOptions }> = ({ linkOptions }) => {
  if (linkOptions?.type === 'corp') return <>Link to organization</>
  return (
    <>
      Link to{' '}
      <RelationTooltip relationDisplay={linkOptions?.relationDisplay}>
        current relation
      </RelationTooltip>
    </>
  )
}

const getFormInitialValues = (allowedTypes: readonly ZDocType[]) =>
  ({
    mode: 'single',
    firstPage: 1,
    lastPage: 1,
    target: 'processing',
    docType: allowedTypes[0] ?? 'PROCESSING',
  }) as const

export const ExtractTabComp: React.FC<{
  pdf?: PDFDocument
  onOptionsChanged?: (value: ExtractOptions) => void
  allowedTypes: readonly ZDocType[]
  linkOptions?: ZLinkOptions
  docForm: Pick<ZDocForm, 'title' | 'type'>
}> = ({ pdf, onOptionsChanged, children, allowedTypes, linkOptions, docForm }) => {
  const form = useForm<ZExtractForm>({
    validate: zodResolver(ZExtractForm),
    initialValues: getFormInitialValues(allowedTypes),
  })

  const extractMutation = useExtractAndUploadMutation(pdf, {
    onSuccess: ({ createdUrl }) =>
      showNotification({
        title: 'Pages extracted!',
        message: (
          <Text size='sm'>
            The document was successfully created. You can access it{' '}
            <NextLinkOpt href={createdUrl} data-testid='extracted-doc-link'>
              here
            </NextLinkOpt>
            .
          </Text>
        ),
        color: 'primary',
      }),
  })

  // No need for React.useMemo because this value is already memoized inside PDFDocument
  const pageCount = pdf?.getPageCount() ?? 1
  const isRange = form.values.mode === 'range'
  const isSingle = form.values.mode === 'single'
  const isLinkTarget = form.values.target === 'link'
  const maxFirstPage = isRange ? pageCount - 1 : pageCount
  const minLastPage = form.values.firstPage + 1
  const maxLastPage = pageCount

  React.useEffect(() => {
    // Cover the situation where the user selects the last page in a menu then switches to Range
    if (form.values.firstPage > maxFirstPage) form.setValues({ firstPage: maxFirstPage })

    if (form.values.lastPage < minLastPage && minLastPage <= maxLastPage)
      form.setValues({ lastPage: minLastPage })
  }, [form, maxFirstPage, minLastPage, maxLastPage])

  React.useEffect(() => {
    onOptionsChanged?.(preparePages(form.values))
  }, [form.values, onOptionsChanged])

  return (
    <form
      style={{ height: '100%' }}
      onSubmit={form.onSubmit((values) => {
        const { firstPage, lastPage, pageRangeStr } = preparePages(values)
        return extractMutation.mutateAsync({
          firstPage,
          lastPage,
          type: prepareDocType(values),
          title: prepareDocTitle(pageRangeStr, docForm),
          linkOptions,
        })
      })}
    >
      <Stack h='100%' gap='lg'>
        <Stack gap='xs'>
          <Radio.Group
            name='Pages'
            label='Extract page range as a new document'
            description='Original document will remain unchanged'
            {...form.getInputProps('mode')}
          >
            <Group w='100%' justify='space-between' mt='xs'>
              <Radio value='single' label='Single' data-testid='extract-single-page-radio' />
              <Radio value='rest' label='To End' disabled={pageCount === 1} />
              <Radio
                value='range'
                label='Range'
                disabled={pageCount === 1}
                data-testid='extract-range-radio'
              />
            </Group>
          </Radio.Group>
          <Group>
            <NumberInput
              w='45%'
              label={!isSingle ? 'First Page' : 'Page'}
              min={1}
              max={maxFirstPage}
              data-testid='extract-first-page-input'
              {...mkFormNumberInputProps(form.getInputProps('firstPage'))}
            />
            {isRange && (
              <NumberInput
                ml='auto'
                w='45%'
                label='Last Page'
                data-testid='extract-last-page-input'
                min={minLastPage}
                max={maxLastPage}
                {...mkFormNumberInputProps(form.getInputProps('lastPage'))}
              />
            )}
          </Group>
        </Stack>

        {children}

        <Stack gap='xs'>
          <Radio.Group
            name='Target'
            label='Extracted Document Destination'
            {...form.getInputProps('target')}
          >
            <Group w='100%' justify='space-between' mt='xs'>
              <Radio value='processing' label='Aerial to sort' />
              <Radio
                data-testid='extract-link-to-relation-radio'
                value='link'
                label={<ExtractLinkLabel linkOptions={linkOptions} />}
                disabled={!linkOptions}
              />
            </Group>
          </Radio.Group>
          <DocTypesSelect
            label='Extracted Document Type'
            data-testid='extract-type-select'
            allowedTypes={isLinkTarget ? allowedTypes : ['PROCESSING']}
            disabled={!isLinkTarget}
            {...(isLinkTarget ? form.getInputProps('docType') : { value: 'PROCESSING' })}
          />
        </Stack>
        <LoadingErrorComp queryResult={extractMutation} variant='tooltip'>
          <Button
            type='submit'
            mb='xs'
            leftSection={
              <ThemeIcon>
                <IconPageBreak />
              </ThemeIcon>
            }
            data-testid='extract-button'
          >
            Extract
          </Button>
        </LoadingErrorComp>
      </Stack>
    </form>
  )
}

export const ExtractTab: React.FC = () => {
  const docForm = useDocDetailState((state) => state.form)
  const allowedTypes = useDocDetailState((state) => state.allowedTypes)
  const linkOptions = useDocDetailState((state) => state.linkOptions)

  // This is outside ExtractTabComp because useDebouncedState causes issues in jest
  const [debouncedOptions, setDebouncedOptions] = useDebouncedState<ExtractOptions>(
    preparePages(getFormInitialValues(allowedTypes)),
    500
  )

  const loadPdfQuery = useLoadAndCheckPdfLibDocument()
  const { pdf, isValid } = loadPdfQuery.data ?? {}

  if (loadPdfQuery.data && !isValid) return <ErrorDisplayComp error='Unsupported PDF format' />

  return (
    <LoadingErrorComp queryResult={loadPdfQuery}>
      <ExtractTabComp
        pdf={pdf}
        onOptionsChanged={setDebouncedOptions}
        allowedTypes={allowedTypes}
        linkOptions={linkOptions}
        docForm={docForm.values}
      >
        {pdf && <ExtractPagesPreview pdf={pdf} {...debouncedOptions} />}
      </ExtractTabComp>
    </LoadingErrorComp>
  )
}

import { z } from 'zod'
import { appendPath, mkUrlWithSearchParams } from '~/common/util'
import { ZStringBoolean } from '~/common/zenv'
import { zenvCommon } from '~/common/zenv-common'

export const DOCUSIGN_SCOPE = ['signature', 'impersonation']

export const ZDocusignRedirectState = z.object({
  clientSuccessRedirectRoute: z.string(),
  clientErrorRedirectRoute: z.string(),
  isOnboardingIntegrations: ZStringBoolean.optional(),
})
export type ZDocusignRedirectState = z.infer<typeof ZDocusignRedirectState>

/** Create the docusign consent URL adding the current corp to the state, to be
 * able to redirect the user back after accepting the scopes.
 * We cannot use the ApiClient in the docusign SDK as it only works in node. */
export const mkDocusignIndividualConsentUrl = (state: ZDocusignRedirectState): string => {
  const redirectUri = appendPath(
    zenvCommon.NEXT_PUBLIC_REDIRECT_URL,
    zenvCommon.NEXT_PUBLIC_DOCUSIGN_CALLBACK_PATH
  )

  // The logout endpoint will prompt an immediate login.
  // https://developers.docusign.com/platform/auth/reference/logout/
  // This enables users to pick which user they want to use for each integration
  // (instead of always using the one that DocuSign remembered )
  return mkUrlWithSearchParams(`https://${zenvCommon.NEXT_PUBLIC_DOCUSIGN_AUTH_HOST}/logout`, {
    response_type: 'code',
    scope: DOCUSIGN_SCOPE.join(' '),
    client_id: zenvCommon.NEXT_PUBLIC_DOCUSIGN_INTEGRATION_KEY,
    redirect_uri: redirectUri,
    state: JSON.stringify(state),
  })
}

// This needs to be imported by the server when throwing the error, and by the
// client to prevent showing a notification when the error is thrown.
export const duplicateDocusignAccountOtherCorpErrorMsg =
  'This DocuSign account is already connected to a different corp'

export const duplicateDocusignAccountSameCorpErrorMsg =
  'This DocuSign account is already connected to this Corp. Please refresh the page'

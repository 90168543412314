import type { CryptId } from '@cryptid-module'
import { getCorpMissingDocsData, mkIndexedDocs } from '~/common/doc'
import { filterRecordsRedFlag } from '~/common/red-flags'
import type { AugmentedMetadataDate, WithIndexNumber, ZAugmentedDoc } from '~/common/schema'
import { ZAugmentedCorp } from '~/common/schema'
import type { ZAllAugmentedRecordsRedFlag } from '~/common/schema/red-flag'
import type { RedFlagInfo } from './util'
import { getActiveStatus, getSourceCryptIds, getStartDateAutofill } from './util'

/**
 * Stub document to make it easy to identify missing docs on front end
 */
export const getCorpSourceCryptIds = (corp: ZAugmentedCorp): CryptId[] => {
  return getSourceCryptIds(corp, ZAugmentedCorp.shape)
}
interface CorpAutofill {
  startDates: (AugmentedMetadataDate & { sourceDisplay?: string })[]
}

export interface EnhancedCorp extends ZAugmentedCorp {
  sourceCryptIds: CryptId[]
  docs: (WithIndexNumber<ZAugmentedDoc> & { key: string })[]
  isActive: boolean
  autofill: CorpAutofill
  display: string
}

export const enhanceCorp = (
  corp: ZAugmentedCorp,
  { filterAutofillDocCryptId }: { filterAutofillDocCryptId?: CryptId } = {}
): EnhancedCorp => {
  const sourceCryptIds = getCorpSourceCryptIds(corp)
  const docs = corp.docOpts.filter(Boolean)
  const enhancedDocs = mkIndexedDocs(docs).map((doc) => ({
    ...doc,
    key: doc.cryptId.idStr,
  }))
  const isActive = getActiveStatus(corp)

  const autofill = {
    startDates: getStartDateAutofill({
      startDate: corp.startDate,
      // Filter autofill for a specific doc
      docOpts: filterAutofillDocCryptId
        ? corp.docOpts.filter((doc) => doc && doc.cryptId.equals(filterAutofillDocCryptId))
        : corp.docOpts,
    }),
  }

  return {
    ...corp,
    docs: enhancedDocs,
    sourceCryptIds,
    isActive,
    autofill,
    display: ZAugmentedCorp.displayFn(corp.name.value),
  }
}

export const getCorpRedFlagInfo = (
  corp: EnhancedCorp,
  redFlags: ZAllAugmentedRecordsRedFlag[]
): RedFlagInfo => {
  const { missingDocs, enhancedMissingDocs, typesWithState } = getCorpMissingDocsData(
    filterRecordsRedFlag(redFlags)
  )
  const missingWithDocs = [...enhancedMissingDocs, ...corp.docs]
  const isComplete = missingDocs.length === 0

  return {
    redFlags,
    isComplete,
    missingDocs,
    typesWithState,
    missingWithDocs,
  }
}

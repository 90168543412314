import type { CryptId } from '@cryptid-module'
import { Collapse, Group, Radio, ScrollArea, Stack, Text, ThemeIcon } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import indefinite from 'indefinite'
import pluralize from 'pluralize'
import React from 'react'
import { difference, sortBy } from 'underscore'
import { z } from 'zod'
import { FormModal } from '~/client/components/modals/form-modal'
import { NextLinkOpt } from '~/client/components/util'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import type { ZDocType } from '~/common/schema'
import { docTypeMap } from '~/common/schema'
import { ZRelationTypeValues, typeAugmentedRelationMap } from '~/common/schema/relation'

const ZRelationCreateForm = z.object({ type: ZRelationTypeValues })
interface ZRelationCreateForm extends z.infer<typeof ZRelationCreateForm> {}

interface AddRelationModalProps {
  opened: boolean
  onClose: () => void
  docType: ZDocType
  allowedTypes: ZRelationTypeValues[]
  docCryptId: CryptId
  onCreate?: (relationCryptId: CryptId) => void
}

export const CreateRelationModal: React.FC<AddRelationModalProps> = ({
  onClose,
  opened,
  docType,
  allowedTypes,
  docCryptId,
  onCreate,
}) => {
  const { corpCryptId } = useCorpCryptId()
  const relationCreate = hooks.trpc().relation.create.useMutationWithCorp()
  const disallowedTypes = difference(ZRelationTypeValues.options, allowedTypes)

  // We have to sort based on the display strings, not the type strings
  const allowedTypesSorted = sortBy(allowedTypes, (type) => typeAugmentedRelationMap[type].display)
  const disallowedTypesSorted = sortBy(
    disallowedTypes,
    (type) => typeAugmentedRelationMap[type].display
  )

  if (!allowedTypesSorted[0]) throw new Error(`No allowed relation types for ${docType}`)
  const form = useForm<ZRelationCreateForm>({
    initialValues: { type: allowedTypesSorted[0] },
    validate: zodResolver(ZRelationCreateForm),
  })
  const [showDisallowed, setShowDisallowed] = React.useState(false)

  return (
    <FormModal
      opened={opened}
      onClose={onClose}
      title='Create Relation'
      isLoading={relationCreate.isLoading}
      onSubmit={form.onSubmit(async (values) => {
        const { cryptId } = await relationCreate.mutateAsync({
          relation: {
            ...typeAugmentedRelationMap[values.type].empty(corpCryptId),
            docCryptIds: [docCryptId],
          },
        })
        onCreate?.(cryptId)
        onClose()
      })}
      submitButtonLabel='Confirm'
    >
      <ScrollArea.Autosize mah='50vh'>
        <Radio.Group
          defaultValue={allowedTypes[0]}
          label='Select the relation type to create:'
          {...form.getInputProps('type')}
        >
          <Stack gap='xs' mt='xs'>
            {allowedTypesSorted.map((type) => (
              <Radio key={type} value={type} label={typeAugmentedRelationMap[type].display} />
            ))}

            <Collapse in={showDisallowed}>
              <Stack gap='xs'>
                {disallowedTypesSorted.map((type) => (
                  <Radio
                    key={type}
                    value={type}
                    label={typeAugmentedRelationMap[type].display}
                    disabled
                  />
                ))}
              </Stack>
            </Collapse>

            {disallowedTypes.length > 0 && (
              <NextLinkOpt size='xs' onClick={() => setShowDisallowed(!showDisallowed)}>
                <Group gap='sm'>
                  <ThemeIcon size='sm'>
                    {showDisallowed ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
                  </ThemeIcon>
                  <Text>
                    {showDisallowed ? 'Hide' : 'Show'} {disallowedTypes.length} disallowed relation{' '}
                    {pluralize('type', disallowedTypes.length)} for{' '}
                    {indefinite(docTypeMap[docType].short)} document
                  </Text>
                </Group>
              </NextLinkOpt>
            )}
          </Stack>
        </Radio.Group>
      </ScrollArea.Autosize>
    </FormModal>
  )
}

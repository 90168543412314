import { CryptId } from '@cryptid-module'
import { z } from 'zod'

export const ZEmailLowerCase = z
  .string()
  .email()
  .transform((e): string => e.toLowerCase())

export type ZEmailLowerCase = z.infer<typeof ZEmailLowerCase>

export const ZCryptId = z.instanceof(CryptId)
export type ZCryptId = z.infer<typeof ZCryptId>

export const ZRelationFilter = z.object({ hasEnded: z.boolean() })
export type ZRelationFilter = z.infer<typeof ZRelationFilter>

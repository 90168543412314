import type { CryptId } from '@cryptid-module'
import superjson from 'superjson'
import type { z } from 'zod'
import type { DocTypeState } from '~/common/doc'
import type { WithIndexNumber, ZAugmentedDoc, ZMissingDoc } from '~/common/schema'
import { AugmentedMetadataDate, type ZAugmentedCorp, docTypeStr } from '~/common/schema'
import type { ZAllAugmentedRecordsRedFlag } from '~/common/schema/red-flag'
import type { ZAugmentedRelation } from '~/common/schema/relation'
import { getMetadataKeys } from '~/common/schema/relation/util'
import type { EnhancedDoc } from './doc'

export interface RedFlagInfo {
  isComplete: boolean
  missingDocs: ZMissingDoc[]
  redFlags: ZAllAugmentedRecordsRedFlag[]
  typesWithState: [ZAugmentedDoc['type'], DocTypeState][]
  missingWithDocs: MissingOrDoc[]
}

export const getSourceCryptIds = (
  data: ZAugmentedRelation | ZAugmentedCorp,
  shape: z.ZodRawShape
): CryptId[] => {
  const metadataKeys = getMetadataKeys(shape)
  return metadataKeys
    .map((key) => {
      const prop = data[key as keyof typeof data]
      // Check just to be sure and to keep typesafety
      return prop && typeof prop === 'object' && 'sourceCryptId' in prop
        ? prop.sourceCryptId
        : undefined
    })
    .filter(Boolean) // Remove undefined values if something wrong happened
}

type WithKey<T> = T & { key: string }

export type MissingOrDoc =
  | WithKey<ZMissingDoc>
  | EnhancedDoc
  | WithKey<WithIndexNumber<ZAugmentedDoc>>

export const getActiveStatus = (
  data: { startDate?: AugmentedMetadataDate; endDate?: AugmentedMetadataDate },
  now: Date = new Date()
): boolean =>
  (!data.startDate?.value || data.startDate.value <= now) &&
  (!data.endDate?.value || data.endDate.value > now)

// Dedupe in O(1) with Set
export const dedupeNonNullSuperjson = <T>(values: (T | undefined)[]): T[] => {
  const deduped = new Set<string>()
  values.forEach((value) => value && deduped.add(superjson.stringify(value)))
  return Array.from(deduped).map((str) => superjson.parse<T>(str))
}

export const dedupeMetadataDates = (dates: AugmentedMetadataDate[]): AugmentedMetadataDate[] => {
  const nonNullDates = dates.filter((date) => date.value)

  // dedupe with Map instead of set because we need to preserve the objects
  // this will not contain any undefined values, but TS doesn't know
  const dedupedMap = new Map<string | undefined, AugmentedMetadataDate>(
    nonNullDates.map((date) => [date.value?.toISOString(), date])
  )

  return Array.from(dedupedMap.values())
}

export const getStartDateAutofill = (
  data: Pick<ZAugmentedCorp | ZAugmentedRelation, 'startDate' | 'docOpts'>
): AugmentedMetadataDate[] =>
  dedupeMetadataDates(
    dedupeNonNullSuperjson([
      data.startDate?.value ? data.startDate : undefined,
      ...data.docOpts.map((doc) =>
        doc && 'startDate' in doc && doc.startDate
          ? {
              sourceCryptId: doc.cryptId,
              type: 'document' as const,
              value: doc.startDate,
              sourceDisplay: `${AugmentedMetadataDate.display(doc.startDate)} from ${
                doc.title ?? docTypeStr(doc.type)
              }`,
            }
          : undefined
      ),
    ])
  )

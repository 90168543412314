import { useDebouncedState as useDebouncedValue } from '@mantine/hooks'
import type { UseInfiniteQueryResult } from '@tanstack/react-query'
import * as React from 'react'
import { hooks } from '~/client/lib/hooks'
import { nextPageParamOpts } from '~/client/lib/hooks/query'
import { splitTypeAndTextQueries } from '~/client/lib/hooks/search/util'
import type { ZNumberExceed } from '~/common/number-exceed'
import type { Paginated, ZAugmentedDocWithHighlights, ZDocType } from '~/common/schema'
import { ZAugmentedDoc } from '~/common/schema'

/**
 * Debounced search queries.  Manages debounced state
 * @param queries from URL
 * @returns
 */
export const useDocSearchState = (
  queries: string[] = []
): [string[], (value: string[]) => void] => {
  const [debouncedValue, setDebouncedValue] = useDebouncedValue<string[]>(queries, 500)
  React.useEffect(() => {
    setDebouncedValue(queries)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries.toString()])

  return [debouncedValue, setDebouncedValue]
}

export type DocSearchResults = UseInfiniteQueryResult<Paginated<ZAugmentedDocWithHighlights>>

interface UseDocSearch {
  noQuery: boolean
  docTypes: ZDocType[]
  setQuery: (value: string[]) => Promise<void>
  searchResults: DocSearchResults
}

export const useDocSearch = (queries: string[] = []): UseDocSearch => {
  const [debouncedValue, setDebouncedValue] = useDocSearchState(queries)

  const { texts, types } = splitTypeAndTextQueries(debouncedValue)
  const docTypes = types.filter(ZAugmentedDoc.isType)

  const searchResults = hooks
    .trpc()
    .docs.search.useInfiniteQueryWithCorp(
      { types: docTypes, query: texts, limit: 10 },
      { keepPreviousData: true, ...nextPageParamOpts() }
    )

  const trpcContext = hooks.trpc().useContext()
  const setQuery = React.useCallback(
    async (v: string[]) => {
      setDebouncedValue(v)
      await trpcContext.docs.search.cancel()
    },
    [setDebouncedValue, trpcContext.docs.search]
  )

  const noQuery = docTypes.length === 0 && texts.length === 0

  return { setQuery, docTypes, searchResults, noQuery }
}

export const mkDocsAndCount = (
  searchResults: DocSearchResults
): { docs: ZAugmentedDocWithHighlights[]; count: ZNumberExceed } => {
  const docs = searchResults.data?.pages.map((page) => page.data).flat() ?? []
  const count = searchResults.data?.pages[0]?.count ?? { count: 0, exceeds: false }
  return { docs, count }
}

import type { ButtonProps } from '@mantine/core'
import { Button, ScrollArea, Stack, Title } from '@mantine/core'
import React from 'react'
import { DocusignManagementComp } from '~/client/components/integration/docusign/docusign-management-comp'
import { useIntegrationModals } from '~/client/components/integration/modal-hooks'
import { BaseModal } from '~/client/components/modals'

export type ModalButtonStatus = 'initial' | 'loading' | 'done'

const buttonProps = (status: ModalButtonStatus): ButtonProps => {
  switch (status) {
    case 'initial': {
      return {
        variant: 'outline',
        color: 'gray',
      }
    }
    case 'loading': {
      return {
        variant: 'outline',
        color: 'gray',
        disabled: true,
      }
    }
    case 'done':
      return {}
  }
}

interface DocusignManagementModalProps {
  /** To be used only on storybook */
  forceOpen?: boolean
}
export const DocusignManagementModal: React.FC<DocusignManagementModalProps> = ({ forceOpen }) => {
  const { openedIntegration, closeIntegrationModal } = useIntegrationModals()

  const [modalButtonStatus, setModalButtonStatus] = React.useState<ModalButtonStatus>('initial')

  return (
    <BaseModal
      title={
        <Title order={3} pl='sm'>
          Configure DocuSign Integrations
        </Title>
      }
      onClose={() => {
        closeIntegrationModal()
      }}
      opened={forceOpen ?? openedIntegration === 'docusign'}
      centered={false}
      trapFocus={false}
    >
      <Stack gap='sm'>
        <ScrollArea.Autosize type='auto' mah='70vh'>
          <DocusignManagementComp setModalButtonStatus={setModalButtonStatus} />
        </ScrollArea.Autosize>
        <Button
          style={{ alignSelf: 'flex-end' }}
          mr='md'
          onClick={() => closeIntegrationModal()}
          {...buttonProps(modalButtonStatus)}
          data-testid='docusign-modal-button'
        >
          {modalButtonStatus === 'done' ? 'Done' : 'Cancel'}
        </Button>
      </Stack>
    </BaseModal>
  )
}

import React, { useCallback } from 'react'
import type { z } from 'zod'
import { fillDeletedRelations } from '~/client/lib/deleted-relation'
import { hooks } from '~/client/lib/hooks'
import type { DownloadRelationXLSXOpt } from '~/client/lib/relations-xlsx'
import { downloadRelationsXLSXFile } from '~/client/lib/relations-xlsx'
import { enhanceRelation } from '~/common/enhance'
import { exhaustPaginated } from '~/common/exhausted-query'
import type { ZRelationFilter } from '~/common/schema'
import type {
  AllAugmentedRelationsMap,
  AugmentRelation,
  ZRelationTypeValues,
} from '~/common/schema/relation'

interface UseDownloadRelationsXLSXFile {
  isLoading: boolean
  downloadRelationsXLSXFile: <RelationTypes extends ZRelationTypeValues>(
    types: RelationTypes[],
    filter: ZRelationFilter | undefined,
    options: Omit<
      DownloadRelationXLSXOpt<z.infer<AllAugmentedRelationsMap[RelationTypes]>>,
      'corpName'
    >
  ) => Promise<void>
}

export const useDownloadRelationsXLSXFile = (): UseDownloadRelationsXLSXFile => {
  const [isLoading, setIsLoading] = React.useState(false)
  const fetchRelations = hooks.trpc().relations.byType.useFetchWithCorp()
  const { data: corpData } = hooks.useCurrentCorp()

  const downloadRelationsXLSXFileWithState = useCallback(
    async <RelationTypes extends ZRelationTypeValues>(
      types: RelationTypes[],
      filter: ZRelationFilter | undefined,
      options: Omit<
        DownloadRelationXLSXOpt<z.infer<AllAugmentedRelationsMap[RelationTypes]>>,
        'corpName'
      >
    ) => {
      try {
        setIsLoading(true)

        const relations: AugmentRelation<{ type: RelationTypes }>[] = await exhaustPaginated(
          fetchRelations,
          {
            types,
            filter,
            limit: Infinity,
            direction: 1,
            sortField: '_id',
          }
        )

        const enhancedRelations = relations.map((r) => enhanceRelation(r))

        return await downloadRelationsXLSXFile(
          options.excludeMissingIndexes
            ? enhancedRelations
            : fillDeletedRelations(enhancedRelations),
          { ...options, corpName: corpData?.name.value ?? null }
        )
      } finally {
        setIsLoading(false)
      }
    },
    [fetchRelations, corpData]
  )
  return { isLoading, downloadRelationsXLSXFile: downloadRelationsXLSXFileWithState }
}

import type { PillsInputFieldProps } from '@mantine/core'
import { Box, CloseButton, Group, Loader, Text } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import * as React from 'react'
import {
  MultiSelectCreatable,
  type MultiSelectCreatableProps,
  type OptionCompProps,
  type PillCompProps,
} from '~/client/components/multi-select-creatable'
import { zIndex } from '~/client/components/z-index'
import type { UseSearchOptions } from '~/client/lib/hooks/search'
import { isTypeOption } from '~/client/lib/hooks/search'
import { theme } from '~/client/lib/theme'
import { grayBackgroundOnSelectedOptionClass } from './multi-select-search-input.css'

const PillComp: React.FC<PillCompProps> = ({ item, onRemove }) => {
  const isDocOrRelation = isTypeOption(item.value)
  return (
    <Box>
      <Group
        style={{
          border: `1px solid ${isDocOrRelation ? theme.colors.primary[7] : theme.colors.gray[4]}`,
          color: isDocOrRelation ? theme.colors.primary[7] : theme.colors.gray[7],
          borderRadius: 4,
          gap: theme.spacing.xs,
        }}
        px={8}
        h={25}
      >
        <Text fw={500} lh={1} fz='sm'>
          {isDocOrRelation ? item.label : `"${item.label}"`}
        </Text>
        {onRemove ? (
          <CloseButton
            onMouseDown={onRemove}
            variant='transparent'
            size='sm'
            iconSize={14}
            tabIndex={-1}
            color={isDocOrRelation ? 'primary.7' : 'gray.7'}
          />
        ) : null}
      </Group>
    </Box>
  )
}

const OptionComp: React.FC<OptionCompProps> = ({ item }) => {
  return (
    <Group>
      <PillComp item={item} />
    </Group>
  )
}

export interface MultiSelectSearchInputProps
  extends MultiSelectCreatableProps,
    Pick<PillsInputFieldProps, 'placeholder'> {
  options: UseSearchOptions
  multiline?: boolean
  isFetching?: boolean
  ref?: React.ForwardedRef<HTMLInputElement>
}

export const MultiSelectSearchInput: React.ForwardRefExoticComponent<MultiSelectSearchInputProps> =
  React.forwardRef(
    (
      { options, multiline, isFetching, onSearchChange, placeholder, onChange, ...selectProps },
      forwardedRef
    ) => {
      return (
        <MultiSelectCreatable
          {...selectProps}
          ref={forwardedRef}
          pillsInputFieldProps={{ placeholder }}
          pillsInputProps={{
            leftSection: isFetching ? <Loader size='sm' /> : <IconSearch />,
            size: 'md',
            style: { flex: 'auto', overflowY: 'auto' },
            mah: multiline ? 'auto' : 42,
            'data-testid': 'search-pill-input',
          }}
          onChange={(values: string[]) => {
            options.onChange(values)
            onChange?.(values)
          }}
          data={options.data}
          clearable
          creatable
          onCreate={(text) => options.create(text)}
          getCreateLabel={(text) => `Search "${text}"`}
          createOptionGroup='Search'
          pillComp={PillComp}
          optionComp={OptionComp}
          filter={options.filter}
          comboboxProps={{
            withinPortal: true,
            zIndex: zIndex.modal,
          }}
          dropdownClassName={grayBackgroundOnSelectedOptionClass}
          onSearchChange={onSearchChange}
          // We want to always show the scrollbar to make the results further
          // down more discoverable
          scrollbarType='always'
        />
      )
    }
  )

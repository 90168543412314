import type { DateInputProps } from '@mantine/dates'
import { DateInput } from '@mantine/dates'
import React from 'react'
import { fromUTCDate, toUTCDate } from '~/common/date-utc'

export const DatePickerInputUTC = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ onChange, value, ...props }, ref) => (
    <DateInput
      clearButtonProps={{
        'aria-label': 'Clear Date',
      }}
      clearable
      {...props}
      ref={ref}
      onChange={onChange && ((v) => onChange(toUTCDate(v) ?? null))}
      value={fromUTCDate(value) ?? null}
    />
  )
)

import type { DateInputProps } from '@mantine/dates'
import { IconCalendar } from '@tabler/icons-react'
import * as React from 'react'
import { DatePickerInputUTC } from '~/client/components/util/date-picker-utc'
import { inlineInputWidth } from '~/client/components/util/inline/util'
import type { RelationAutofill } from '~/common/enhance'
import { AugmentedMetadataDate } from '~/common/schema'
import type { InlineCommonProps } from './base'
import { InlineBaseInput } from './base'

interface InlineDateInputProps extends DateInputProps, InlineCommonProps<AugmentedMetadataDate> {
  autofillData?: RelationAutofill['startDates']
}

export const InlineDateInput: React.FC<InlineDateInputProps> = ({
  update,
  initialValue,
  docs,
  autofillData,
  alignment,
  noWrap,
  ...props
}) => {
  const autofills = React.useMemo(
    () => autofillData?.map((doc) => doc.sourceDisplay ?? ''),
    [autofillData]
  )
  return (
    <InlineBaseInput
      initialValues={initialValue}
      schema={AugmentedMetadataDate.pickValueSchema}
      update={update && (({ metadata }) => update(metadata))}
      display={AugmentedMetadataDate.display(initialValue?.value)}
      docs={docs}
      alignment={alignment}
      autofills={autofills}
      autofillData={autofillData}
      valueIsFilled={!!initialValue?.value}
      noWrap={noWrap}
      dropOverContent={({ formGetInputProps }) => (
        <DatePickerInputUTC
          placeholder='Date'
          firstDayOfWeek={0}
          leftSection={<IconCalendar size={16} />}
          styles={{
            input: { width: inlineInputWidth },
          }}
          {...props}
          {...formGetInputProps('value')}
          // DatePicker popover should set `withinPortal` to false, because
          // clicking a date will close the parent popover (inline edit
          // popover).
          // https://github.com/mantinedev/mantine/issues/2144#issuecomment-1217173512
          popoverProps={{ ...props.popoverProps, withinPortal: false }}
        />
      )}
    />
  )
}

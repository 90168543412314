import { Stack } from '@mantine/core'
import { type PDFDocument } from 'pdf-lib'
import React, { useEffect } from 'react'
import { PdfIframe } from '~/client/components/doc-detail/iframe'
import { type ExtractOptions, extractPdfPages } from '~/client/lib/hooks/extract-pages'
import { useObjectURL } from '~/client/lib/hooks/use-object-url'

interface ExtractPagesPreviewProps extends ExtractOptions {
  pdf: PDFDocument
}

export const ExtractPagesPreview: React.FC<ExtractPagesPreviewProps> = ({
  pdf,
  firstPage,
  lastPage,
}) => {
  const [blob, setBlob] = React.useState<Blob>()
  useEffect(() => {
    ;(async () => {
      const bytes = await extractPdfPages(pdf, { firstPage, lastPage })
      setBlob(new Blob([bytes], { type: 'application/pdf' }))
    })()
  }, [pdf, firstPage, lastPage])

  const previewUrl = useObjectURL(blob)

  return (
    <Stack style={{ flexGrow: 1 }}>
      <PdfIframe padding={2} url={previewUrl} title='Extract Preview' />
    </Stack>
  )
}
